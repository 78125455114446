import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Navigation from '../components/navigation/navigation';
import Auth from './auth';

let managerRouts = [
  '/manager-profile',
  '/manager-orders',
  '/manager-orders/edit/:id',
  '/manager-clients',
  '/manager-clients/edit/:id',
  '/support'
];

export const ProtectedRoute = ({ component: Node, ...rest }) =>{
   
  let isAdmin = Auth.isAdmin();

  if( Auth.isAuthenticated()){
    
    if((!isAdmin) && (!managerRouts.includes(rest.path))){
      return (
        <Route {...rest} render={(props) =>{
          return(
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }} />
      )
    }

    return(
      <div className="app">
         <div className="sidebar">
            <Navigation></Navigation>
         </div>
         <div className="content">
            <Route {...rest} render={(props) =>{
              return(
                <Node {...props} />
              )
            }} />
          </div>
      </div>
    )
  }else{
    return (
      <Route {...rest} render={(props) =>{
        return(
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }} />
    )
  }
};
  