import React from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from '../../config';
import ImageUploading from "react-images-uploading";
import Auth from '../../auth/auth';
import { ToastContainer, toast } from 'react-toastify';
import headers from "../../auth/headers";
import './support.css';

const Support = ({  }) => {

    const [images, setImages] = React.useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    return (
        <div className="content_body">
            <ToastContainer></ToastContainer>
            <div className="content_top">
                <div className="row">
                    <div className="col-9">
                        <h1>Служба поддержки</h1>
                     </div>
                 </div>
            </div>
            <div className="block">
                <div className="modal_block">
                <Formik
                        initialValues={{ subject: "", text: "", phone: "", email: "", need_call: false, need_email: false}}
                        onSubmit={values => {

                            values.manager_id = Auth.getManagerId();
                            if(images.length){
                                let base64 = images[0]['data_url']
                                values.screenshot = base64;
                            }

                            axios.post(`${config.api}requests/create`, values, headers)
                            .then(res => {
                                toast.success("Отправлено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }).catch((err => {
                                toast.error("Ошибка отправления", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            subject: Yup.string().required('Обязательно'),
                            text: Yup.string().required('Обязательно'),
                            // phone: Yup.string().required('Обязательно'),
                            email: Yup.string().email().required('Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            } = props;
                            return (
                                <div className="row">
                                    <form onSubmit={handleSubmit} className="col-9 b-form">
                                        <h2 className="support-title text-center">Форма обратной связи</h2>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form_group">
                                                    <label>Тема запроса</label>
                                                    <input
                                                        type="text"
                                                        value={values.subject}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="subject"
                                                        placeholder="Опишите кратко Ваш вопрос"
                                                        className={
                                                            errors.subject && touched.subject
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.subject && touched.subject && (
                                                        <div className="form_error">{errors.subject}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Детальное описание вопроса</label>
                                                    <textarea
                                                        rows={10}
                                                        type="text"
                                                        value={values.text}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="text"
                                                        placeholder="Описание"
                                                        className={
                                                            errors.text && touched.text
                                                                ? "text-input error"
                                                                : "text-input"
                                                        }
                                                    />
                                                    {errors.text && touched.text && (
                                                        <div className="form_error">{errors.text}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <ImageUploading
                                                        multiple
                                                        value={images}
                                                        onChange={onChange}
                                                        maxNumber={1}
                                                        dataURLKey="data_url"
                                                    >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                        

                                                            <div className="col-8">
                                                                <div className="form_group">
                                                                    <label>Скриншот запроса</label>
                                                                    {
                                                                        imageList.length < 1 &&
                                                                        <button
                                                                            className="btn btn_black"
                                                                            onClick={onImageUpload}
                                                                            {...dragProps}
                                                                        >
                                                                            Выбрать файл
                                                                        </button>
                                                                    }
                                                                    
                                                                    {
                                                                        imageList.map((image, index) => (
                                                                            <div key={index} className="row">
                                                                                <div className="col-6">
                                                                                    <div className="logo_wrapper">
                                                                                        <img src={image.data_url} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="image-item__btn-wrapper">
                                                                                        <div className="form_group">
                                                                                            <button onClick={() => onImageUpdate(index)}  className="btn btn_black">Обновить</button>
                                                                                        </div>
                                                                                        <div className="form_group">
                                                                                            <button onClick={() => onImageRemove(index)}  className="btn btn_black">Удалить</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Номер</label>
                                                    <input
                                                        type="text"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="phone"
                                                        placeholder="+38093 123 45 67"
                                                        className={
                                                            errors.phone && touched.phone
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="form_error">{errors.phone}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex align-items-end pb-4">
                                                <div className="form_group d-flex align-items-center">
                                                    <Field type="checkbox" name="need_call" />
                                                    <label className="p-0 pl-2">Нужен звонок оператора?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Email *</label>
                                                    <input
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="email"
                                                        placeholder="email@gmail.com"
                                                        className={
                                                            errors.email && touched.email
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="form_error">{errors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex align-items-end pb-4">
                                                <div className="form_group d-flex align-items-center">
                                                    <Field type="checkbox" name="need_email" />
                                                    <label className="p-0 pl-2">Нужен ответ письмом на e-mail?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <button className="save_button">Сохранить</button>
                                        </div>
                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </div>
    )
}

export default Support;
