import React from 'react';
import axios from "axios";
import config from '../../config';
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import Select from 'react-select';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import headers from "../../auth/headers";
import formatDate from "../../date";
import Edit from '../managerOrders/edit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DatePicker2 from "../datePicker/datePicker";
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import Popup from 'reactjs-popup';
import 'react-day-picker/lib/style.css';
import './orders.css';

const products = [
    { value: null, label: 'Выбрать товар' }
]

const statuses = [
    { value: null, label: 'Выбрать статус' },
    { value: 'sent', label: 'Заказ отправлен в работу' },
    { value: 'processing', label: 'Заказ в обработке' },
    { value: 'billed', label: 'Выставлен счет от типографии' },
    { value: 'production', label: 'Заказ в производстве' },
    { value: 'printed', label: 'Заказ напечатан' },
    { value: 'sent_to_client', label: 'Заказ отправлен клиенту' },
]


export default class Orders extends React.Component{
    constructor(props){
        super(props);
        this.state = { startDate: null, endDate: null, products: products, product: products[0],status: statuses[0],  data: [], id: null, searchText: ''};
        this.onStartChange = this.onStartChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onOpenEdit = this.onOpenEdit.bind(this);
        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
    }
    onResetClick(){
        this.setState({ startDate: null,  endDate: null, searchText: '', product: products[0], status: statuses[0]});
        let _this = this;
        setTimeout(()=>{
            _this.fetch();
        },1500)
    }
    onSearchTextChange(e){
        this.setState({ searchText: e.target.value });
    }
    onCloseEdit(e){
        this.setState({ id: null });
    }
    onOpenEdit(e){
        this.setState({ id: e.target.value });
    }
    onDeleteClick(e){
        const _this = this;
        axios.post(`${config.api}orders/delete`, { _id: e.target.value }, headers).then((res) => {
            _this.fetch();
         }
        ).catch((err => {
            console.log(err);
        }));
    }
    getProducts(){
        const _this = this;
        return new Promise((resolve, reject)=>{
            axios.get(`${config.api}products/list?start=null&end=null`, headers).then((res) => {
                res.data.forEach(product => {
                    products.push({
                        value: product._id,
                        label: product.title_ru
                    });
                });
                _this.setState({ products });
                resolve();
             }
            ).catch((err => {
                console.log(err);
            }));
        });
    }
    onStatusChange(e){
        this.setState({ status: e });
    }
    onProductChange(e){
        this.setState({ product: e });
    }
    componentDidMount(){
        const _this = this;
        this.getProducts().then(()=>{
            _this.fetch();
        });
    }
    fetch(){
        const _this = this;
        axios.get(`${config.api}orders/list?start=${this.state.startDate}&end=${this.state.endDate}&product=${this.state.product.value}&status=${this.state.status.value}&text=${this.state.searchText}`,headers).then((res) => {
            _this.setState({ data: res.data, originalData : res.data });
         }
        ).catch((err => {
            console.log(err);       
        }));
    }
    onStartChange(e){
        this.setState({ startDate:e });
    }
    onEndChange(e){
        this.setState({ endDate:e });
    }
    onSearchClick(){
        this.fetch();
    }
    onCopyClick(e){
        let link = e.target.value;
        var input = document.createElement('input');
        input.setAttribute('value', link);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }
    render(){

          const columns = [
            {
              name: 'Менеджер ID',
              selector: 'manager_no',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.manager_no}</p>
                        }
                        </div>
                    )
                },
            },
            {
              name: 'Юридическое лицо менеджера',
              selector: 'legal_entity',
              sortable: true,
                width: '140px',
              center: true,
            },
              {
                  name: 'Статус заказа',
                  selector: 'status',
                  sortable: true,
                  center: true,
                  width: '200px',
                  cell: row =>{
                      return(
                          <div data-tag="allowRowEvents">
                              {
                                  row.status === 'new' &&
                                  'Новый'
                              }
                              {
                                  row.status === 'sent' &&
                                  'Заказ отправлен в работу'
                              }
                              {
                                  row.status === 'processing' &&
                                  'Заказ в обработке'
                              }
                              {
                                  row.status === 'billed' &&
                                  'Выставлен счет от типографии'
                              }
                              {
                                  row.status === 'production' &&
                                  'Заказ в производстве'
                              }
                              {
                                  row.status === 'printed' &&
                                  'Заказ напечатан'
                              }
                              {
                                  row.status === 'sent_to_client' &&
                                  'Заказ отправлен клиенту'
                              }
                          </div>
                      )
                  }
              },
            {
              name: '№',
              selector: 'no',
              sortable: true,
                width: '100px',
              center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.no}</p>
                        }
                        </div>
                    )
                },
            },
          {
              name: 'Компания Клиента',
              selector: 'company',
              sortable: true,
              width: '140px',
              center: true
          },
          {
              name: 'Наименование товара',
              selector: 'title',
              sortable: true,
              width: '140px',
              center: true
          },
          {
              name: 'Артикул',
              selector: 'articule',
              sortable: true,
              width: '140px',
              center: true
          },
          {
              name: 'Создание',
              selector: 'date',
              sortable: true,
              width: '140px',
              center: true,
              cell: row =>{
                  return(
                      <div data-tag="allowRowEvents">{
                          formatDate(row.date)
                      }</div>
                  )
              }
          },
          {
              name: 'Готовность',
              selector: 'ready_at',
              sortable: true,
              width: '140px',
              center: true,
              cell: row =>{
                  return(
                      <div data-tag="allowRowEvents">{
                          formatDate(row.ready_at)
                      }</div>
                  )
              }
          },
          {
              name: 'Имя',
              selector: 'firstname',
              sortable: true,
              width: '140px',
              center: true,
          },
          {
              name: 'Фамилия',
              selector: 'lastname',
              sortable: true,
              width: '140px',
              center: true,
          },
            {
                name: 'Номер телефона',
                selector: 'phone',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Почта',
                selector: 'email',
                sortable: true,
                center: true,
                width: '160px',
                wrap: true,
            },
            {
                name: 'Кол-во',
                selector: 'quantity',
                sortable: true,
                width: '100px',
                center: true,
            },
              {
                  name: 'Размер',
                  selector: 'size',
                  sortable: true,
                  width: '140px',
                  center: true,
                  cell: row =>{
                      let sizes = row.size ? row.size.map((s,i)=>{
                          return <p key={i}>{s.size.label}</p>
                      }): [];
                      return(
                          <div data-tag="allowRowEvents">
                              {
                                  sizes
                              }
                          </div>
                      )
                  }
              },
            {
                name: 'Адрес доставки',
                selector: 'address',
                sortable: true,
                width: '160px',
                center: true,
            },
            {
                name: 'Комментарий',
                selector: 'comment',
                sortable: true,
                width: '200px',
                center: true,
            },
            {
                name: 'Превью заказа',
                selector: 'preview',
                sortable: true,
                center: true,
                width: '200px',
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <div className="cell_image" >
                                <img src={config.api + row.preview} alt="img"></img>
                            </div>
                        }</div>
                    )
                }
            },
            {
                name: 'Сумма заказа',
                selector: 'total',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.total ?
                                row.total.toFixed(2):''
                        }</div>
                    )
                }
            },
              {
                  name: 'Сумма типографии',
                  selector: 'typography_total',
                  sortable: true,
                  width: '140px',
                  center: true,
                  cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.typography_total ?
                                row.typography_total.toFixed(2):''
                        }</div>
                    )
                  }
              },
              {
                  name: 'Сумма бонуса',
                  selector: 'bonus',
                  sortable: true,
                  width: '140px',
                  center: true,
                  cell: row =>{
                      return(
                          <div data-tag="allowRowEvents">{
                              row.bonus ?
                                  row.bonus.toFixed(2):''
                          }</div>
                      )
                  }
              },
            {
                name: 'Скачать превью',
                selector: 'png',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                           <a href={config.api + row.preview }  target="_blank">Скачать превью</a>
                        }</div>
                    )
                }
            },
            {
                name: 'Скачать полный макет',
                selector: 'pdf',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                           <a href={config.api + row.pdf } target="_blank">Скачать полный макет</a>
                        }</div>
                    )
                }
            },
            {
                name: 'Скачать оригинал',
                selector: 'link',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.link && 
                            <button className="btn-admin" value={row.link} onClick={this.onCopyClick}>Копировать ссылку</button>
                        }</div>
                    )
                }
            },
            // ,
            // {
            //     name: '',
            //     width: '200px',
            //     selector: 'edit',
            //     cell: row =>{
            //         return(
            //             <div className="grid_control_wrapper">
            //                <div className="button_clickable_edit">
            //                  <button className="grid_delete" onClick={this.onOpenEdit} value={row._id}> Редактировать</button>
            //                  <PenIcon></PenIcon>
            //                </div>
            //             </div>
            //         )
            //     },
            // },
            // {
            //     name: '',
            //     selector: 'delete',
            //     center: true,
            //     cell: row =>{
            //         return(
            //             <div className="grid_control_wrapper">
            //                 <div className="button_clickable">
            //                     <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}> </button>
            //                     <DeleteIcon></DeleteIcon>
            //                 </div>
            //             </div>
            //         )
            //     },
            // }
          ];
          const customStyles = {
            header: {
                style: {
                  minHeight: '56px',
                },
              },
            headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: defaultThemes.default.divider.default,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: '0px',
                  borderLeftColor: defaultThemes.default.divider.default,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                },
              },
            headCells: {
                style: {
                  '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                  },
                },
              },
            cells: {
                style: {
                  '&': {
                      borderRightStyle: 'solid',
                      borderRightWidth: '1px',
                      borderRightColor: defaultThemes.default.divider.default,
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      fontSize: '10px',
                      lineHeight: '140%',
                  }
                },
              },
            rows: {
                style: {
                    minHeight: '120px', // override the row height
                },
            },
          };
          const data = [{ id: 1, title: 'Conan the Barbarian', year: '1982' }];
          createTheme('solarized', {
            context: {
              background: '#cb4b16',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });
          const conditionalRowStyles = [
            {
              when: row => row.status === 'new',
              style: {
                backgroundColor: '#D5F8FE'
              },
            },
            {
                when: row => row.status === 'sent_to_client',
                style: {
                  backgroundColor: '#A2E4D9'
                },
            },
            {
                when: row => (row.status !== 'sent_to_client') &&  (row.status !== 'new'),
                style: {
                  backgroundColor: '#FFF'
                },
            },
          ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Строк на странице',
            rangeSeparatorText: 'из',
        };
           
        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-6">
                            <h1>Заказы менеджеров</h1>
                        </div>
                        <div className="col-6 search_box">
                            <div className="search_text d-flex justify-content-end">
                                <input type="text" onChange={this.onSearchTextChange} value={this.state.searchText} placeholder="Поиск" />
                                <button className="search_btn" onClick={this.onSearchClick}>
                                    <SearchIcon></SearchIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid_top">
                    <div className="row">
                        <div className="date_input date_from">
                            <DatePicker2  onDayChange={this.onStartChange} selectedDays={this.state.startDate}></DatePicker2>
                        </div>
                        <div className="date_input date_to">
                            <DatePicker2  onDayChange={this.onEndChange} selectedDays={this.state.endDate}></DatePicker2>
                        </div>
                        <div className="order-select-product">
                            <Select onChange={this.onProductChange} value={this.state.product} options={this.state.products}></Select>
                        </div>
                        <div className="order-select-status">
                            <Select onChange={this.onStatusChange} value={this.state.status} options={statuses}></Select>
                        </div>
                        <div className="">
                            <button onClick={this.onResetClick} className="reset_btn">
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                        <div className="grid_top__btn-box">
                            <button onClick={this.onSearchClick} className="btn btn_black">Искать</button>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <PerfectScrollbar>
                       <DataTable
                            sortable={true}
                            noHeader={true}
                            columns={columns}
                            theme="solarized"
                            data={this.state.data}
                            customStyles={customStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={'500px'}
                            responsive
                        />
                    </PerfectScrollbar>
                </div>
                <Popup open={this.state.id !== null} onClose={this.onCloseEdit} >
                    {
                        this.state.id && 
                        <div className="edit_content">
                            <PerfectScrollbar>
                               <Edit _id={this.state.id}></Edit>
                            </PerfectScrollbar>
                        </div>
                    }
                </Popup>
            </div>
        )
    }
}