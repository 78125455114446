import React from 'react';
import axios from "axios";
import config from '../../config';
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import Auth from '../../auth/auth';
import headers from "../../auth/headers";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popup from 'reactjs-popup';
import Edit from './edit/edit';
import formatDate from "../../date";
import Orders from '../clients/orders';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './clients.css';

export default class ManagerClients extends React.Component{
    constructor(props){
        super(props);
        this.state = { data: [] , id: null, edit: null , history: null, searchText: '', tableHeight: ''};
        this.fetch = this.fetch.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onOpenEdit = this.onOpenEdit.bind(this);
        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.onOpenHistory = this.onOpenHistory.bind(this);
        this.closeHistory = this.closeHistory.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
    }
    onSearchClick(){
      this.fetch();
    }
    onSearchTextChange(e){
      this.setState({ searchText: e.target.value });
    }
    onOpenHistory(e){
      this.setState({ history: e.target.value });
    }
    closeHistory(){
      this.setState({ history: null });
    }
    onOpenEdit(e){
      this.setState({ id: e.target.value });
    }
    onCloseEdit(e){
      this.setState({ id: null });
    }
    componentDidMount(){
      this.fetch();

      //Set table height
      if(window.innerWidth < 1900) {
        this.setState({ tableHeight: 'calc(100vh - 350px)' });
      } else {
          this.setState({ tableHeight: 'calc(100vh - 430px)' });
      }
    }
    onDeleteClick(e){

      confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="popup-content">
                        <button className="close">
                            <CloseIcon onClick={() => {onClose();}}></CloseIcon>
                        </button>
                        <h2 className="b-popup_title">Вы уверены, что хотите удалить клиента?</h2>
                        <p className="b-popup_text">клиент будет удален безвозвратно</p>
                        <div className="b-popup_btns"> 
                            <button className="b-popup_btn" onClick={() =>{
                             const _this = this;
                             axios.post(`${config.api}clients/delete`, { _id: e.target.value }, headers).then((res) => {
                                  _this.fetch();
                              }
                             ).catch((err => {
                                 console.log(err);
                             }));
                             onClose();
                                }}
                            >
                                Да, удалить
                            </button>
                            <button className="b-popup_btn"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                           Отмена
                            </button>
                        </div>
                </div>
            );
        }
      });  
    }
    fetch(){
        const _this = this;
        axios.get(`${config.api}clients/list?id=${Auth.getManagerId()}&text=${this.state.searchText}`, headers).then((res) => {
            _this.setState({ data: res.data});
          }
        ).catch((err => {
            console.log(err);
        }));
    }

    render(){
          const columns = [
            {
              name: 'ID менеджера',
              selector: 'manager_no',
              sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Юридическое лицо менеджера',
                selector: 'legal_entity',
                sortable: true,
                width: '140px',
                center: true,
              },
            {
                name: 'ID клиента',
                selector: 'no',
                sortable: true,
                width: '140px',
                center: true,
              },
            {
              name: 'Дата регистрации',
              selector: 'date',
              sortable: true,
              width: '140px',
              center: true,
              cell: row =>{
                return(
                    <div data-tag="allowRowEvents">{
                        formatDate(row.date)
                    }</div>
                )
            }
            },
            // {
            //   name: 'Статус',
            //   selector: 'aprooved',
            //   sortable: true,
            //     width: '140px',
            //   center: true,
            //     cell: row =>{
            //         return(
            //             <div data-tag="allowRowEvents">{
            //                 row.aaprooved ? 'Yes' : 'No'
            //             }</div>
            //         )
            //     },
            // },
            {
                name: 'Имя',
                selector: 'firstname',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Фамилия',
                selector: 'lastname',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
              name: 'Компания клиента',
              selector: 'company',
              sortable: true,
                width: '140px',
              center: true,
            },
            {
                name: 'Номер телефона',
                selector: 'phone',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Почта',
                selector: 'email',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
              name: 'История заказов',
              selector: 'history',
              sortable: true,
              width: '200px',
              center: true,
              cell: row =>{
                return(
                    <div className="grid_control_wrapper">
                       <div className="button_clickable_edit">
                          <button className="grid_delete" onClick={this.onOpenHistory} value={row._id}>Посмотреть</button>
                        </div>
                    </div>
                )
              },
            },
            {
              name: 'Коментарии',
              selector: 'comment',
              sortable: true,
                width: '140px',
              center: true,
            },
            {
                name: 'Редактировать',
                width: '200px',
                selector: 'edit',
                center: true,
                cell: row =>{
                    return(
                      <div className="grid_control_wrapper">
                         <div className="button_clickable_edit">
                            <button className="grid_delete" onClick={this.onOpenEdit} value={row._id}><PenIcon></PenIcon> Редактировать</button>
                          </div>
                      </div>
                    )
                },
            },
            {
                name: 'Удалить',
                selector: 'delete',
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                      <div className="grid_control_wrapper">
                        <div className="button_clickable">
                            <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}> </button>
                            <DeleteIcon></DeleteIcon>
                        </div>
                      </div>
                    )
                },
            }
          ];

          const customStyles = {
            header: {
                style: {
                  minHeight: '56px'
                },
              },
              headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: defaultThemes.default.divider.default,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: '1px',
                  borderLeftColor: defaultThemes.default.divider.default,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                },
              },
              headCells: {
                style: {
                  '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                    textAlign: 'center'
                  },
                },
              },
              rows: {
                style: {
                    minHeight: '110px',
                }
              },
              cells: {
                style: {
                  '&': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                    paddingLeft: '8px', 
                    paddingRight: '8px',
                    paddingTop: '28px',
                    paddingBottom: '28px',
                  }
                },
              },
          };

          createTheme('solarized', {
            context: {
              background: '#cb4b16',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });

        const paginationComponentOptions = {
            rowsPerPageText: 'Строк на странице',
            rangeSeparatorText: 'из',
        };
        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-6">
                            <h1>Клиенты </h1>
                        </div>
                        <div className="col-6 search_box">
                            <div className="search_text">
                                <input type="text" onChange={this.onSearchTextChange} value={this.state.searchText} placeholder="Поиск"></input>
                                <button className="search_btn" onClick={this.onSearchClick}>
                                    <SearchIcon></SearchIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <PerfectScrollbar>
                       <DataTable
                            sortable={true}
                            noHeader={true}
                            columns={columns}
                            theme="solarized"
                            data={this.state.data}
                            customStyles={customStyles}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={this.state.tableHeight}
                            responsive
                        />
                    </PerfectScrollbar>
                </div>
                <Popup open={this.state.id !== null} onClose={this.onCloseEdit} >
                    {
                        this.state.id && 
                        <div className="edit_content">
                            <button className="close" onClick={this.onCloseEdit}>
                                <CloseIcon></CloseIcon>
                            </button>
                            <PerfectScrollbar>
                               <Edit _id={this.state.id}></Edit>
                            </PerfectScrollbar>
                        </div>
                    }
                </Popup>
                <Popup open={this.state.history !== null} onClose={this.closeHistory}>
                   <button className="close" onClick={this.closeHistory}>
                        <CloseIcon></CloseIcon>
                   </button>
                   <Orders id={this.state.history} closeEdit={this.closeHistory}></Orders>
                </Popup>
            </div>
        )
    }
}