import React from 'react';
import axios from "axios";
import config from '../../config';
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as CheckbocCheckedIcon } from '../../assets/checkbox_checked.svg';
import { ReactComponent as CheckbocUnCheckedIcon } from '../../assets/checkbox_unchecked.svg';
import { ReactComponent as AddIcon } from '../../assets/add_icon.svg';
import { subcategories } from './categories';
import Edit from './edit/edit';
import Add from './add/add';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popup from 'reactjs-popup';
import headers from "../../auth/headers";
import DatePicker2 from "../datePicker/datePicker";
import 'react-day-picker/lib/style.css';
import './products.css';

const categoryMap = {};
const subCategoriesMap = {};
const types = {
    promo: "Промо",
    business: "Бизнес",
    lux: "Люкс"
}

subcategories.forEach((cat)=>{
    subCategoriesMap[cat.value] = cat.label;
});

export default class Products extends React.Component{
    constructor(props){
        super(props);
        this.state = { startDate: null, endDate: null, data: [], id: null, add: false, tableHeight: '' };
        this.onStartChange = this.onStartChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onOpenEdit = this.onOpenEdit.bind(this);
        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.onOpenAdd = this.onOpenAdd.bind(this);
        this.onCloseAdd = this.onCloseAdd.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
    }
    onResetClick(){
        this.setState({ startDate: null,  endDate: null });
        let _this = this;
        setTimeout(()=>{
            _this.fetch();
        },1500)
    }
    onOpenEdit(e){
        this.setState({ id: e.target.value });
    }
    onCloseEdit(e){
        this.setState({ id: null });
        this.fetch();
    }
    onOpenAdd(e){
        this.setState({ add: true });
    }
    onCloseAdd(e){
        this.setState({ add: false });
        this.fetch();
    }
    getCategories(){
        return new Promise((resolve, reject)=>{
            axios.get(`${config.api}products/categories`, headers)
            .then(res => {
                let responseData = res.data;
                responseData.forEach((cat)=>{
                    categoryMap[cat.id] = cat.name;
                });
                resolve();
            });
        });
    }
    componentDidMount(){
        this.getCategories().then(()=>{
            this.fetch();
        });

        //Set table height
        if(window.innerWidth < 1900) {
            this.setState({ tableHeight: 'calc(100vh - 350px)' });
        } else {
            this.setState({ tableHeight: 'calc(100vh - 430px)' });
        }
    }
    onDeleteClick(e){
        const _this = this;
        axios.post(`${config.api}products/delete-product`, { _id: e.target.value }, headers).then((res) => {
             _this.fetch();
         }
        ).catch((err => {
            console.log(err);
        }));
    }
    fetch(){
        const _this = this;
        axios.get(`${config.api}products/list?start=${this.state.startDate}&end=${this.state.endDate}`).then((res) => {
            _this.setState({ data: res.data });
         }
        ).catch((err => {
            console.log(err);       
        }));
    }
    onSearchChange(e){
        this.setState({ searchText: e.target.value });
    }
    onStartChange(e){
        this.setState({ startDate:e });
    }
    onEndChange(e){
        this.setState({ endDate:e });
    }
    onSearchClick(){
        this.fetch();
    }
    render(){
          const columns = [
            {
              name: 'Артикул товара',
              selector: 'articule',
              sortable: true,
                width: '120px',
                center: true,
            },
            {
              name: 'Название товара',
              selector: 'title_ru',
              sortable: true,
                width: '160px',
              center: true,
            },
            {
                name: 'Редактировать',
                width: '200px',
                selector: 'edit',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                           <div className="button_clickable_edit">
                              <button className="grid_delete" onClick={this.onOpenEdit} value={row._id}><PenIcon></PenIcon> Редактировать</button>
                           </div>
                        </div>
                    )
                },
            },
            {
              name: 'Категория',
              selector: 'category',
              sortable: true,
                width: '140px',
              center: true,
              cell: row =>{
                return(
                    <div data-tag="allowRowEvents">{
                      categoryMap[row.category]
                    }</div>
                )
               },
            },
            {
                name: 'Подкатегория товара',
                selector: 'sub_category',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                  return(
                      <div data-tag="allowRowEvents">{
                        subCategoriesMap[row.sub_category]
                      }</div>
                  )
                }
            },
            {
                name: 'Тип товара',
                selector: 'description_ru',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                  return(
                      <div data-tag="allowRowEvents">{
                        types[row.type]
                      }</div>
                  )
                }
            },
            {
                name: 'Цвет',
                selector: 'colors',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                  let colors = row.colors.map((color, i)=>{
                      return(
                            <div key={i} className="color" style={{ background: color.hex }}></div>
                      )
                  });
                  return(
                      <div data-tag="allowRowEvents">
                          <div className="color_box">{
                        colors
                        }</div>
                      </div>
                  )
                }
            },
            {
                name: 'Размер',
                selector: 'size',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                  let sizes = row.size ? row.size.map((size, i)=>{
                      return(
                          <div key={i}>{ size}</div>
                      )
                  }) :[];
                  return(
                      <div data-tag="allowRowEvents">{
                          <p className="grid_text_big">{sizes}</p>
                      }</div>
                  )
                }
            },
            {
                name: 'Стоимость изделия ₴ 1-5 шт',
                selector: 'price_1_5',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_1_5}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 6-10 шт',
                selector: 'price_6_10',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_6_10}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 11-50 шт',
                selector: 'price_11_50',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_11_50}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 51-100 шт',
                selector: 'price_51_100',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_51_100}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 101-150 шт',
                selector: 'price_101_150',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_101_150}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 151-200 шт',
                selector: 'price_151_200',
                sortable: true,
                width: '110px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_151_200}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 201-500 шт',
                selector: 'price_201_500',
                sortable: true,
                width: '110px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_201_500}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость изделия ₴ 501-1000 шт',
                selector: 'price_501_1000',
                sortable: true,
                width: '110px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.price_501_1000}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость приладки ₴',
                selector: 'makeready_price',
                sortable: true,
                width: '110px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.makeready_price}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Стоимость полной запечатки ₴',
                selector: 'seals_price',
                sortable: true,
                width: '110px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.seals_price}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Итоговая стоимость ₴',
                selector: 'changeover_price',
                sortable: true,
                width: '120px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.changeover_price}</p>
                        }
                        </div>
                    )
                },
            },
              {
                  name: 'Отображать в Конструкторе',
                  selector: 'editable',
                  sortable: true,
                  width: '140px',
                  center: true,
                  cell: row =>{
                      return(
                          <div data-tag="allowRowEvents">{
                              row.editable ? <CheckbocCheckedIcon></CheckbocCheckedIcon> : <CheckbocUnCheckedIcon></CheckbocUnCheckedIcon>
                          }</div>
                      )
                  },
              },
            {
                name: 'Шаблон',
                width: '200px',
                selector: 'template',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper grid_button grid_button_template">
                            <a className="grid_edit" href={`/products/template/${row._id}`}><PenIcon></PenIcon><span>Шаблон</span></a>
                        </div>
                    )
                },
            },
              {
                  name: 'Удалить',
                  selector: 'delete',
                  width: '100px',
                  center: true,
                  cell: row =>{
                      return(
                          <div className="grid_control_wrapper">
                              <div className="button_clickable">
                                  <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}> </button>
                                  <DeleteIcon></DeleteIcon>
                              </div>
                          </div>
                      )
                  },
              },
          ];
          const customStyles = {
            header: {
                style: {
                  minHeight: '56px',
                },
              },
              headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: defaultThemes.default.divider.default,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: '0px',
                  borderLeftColor: defaultThemes.default.divider.default,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                },
              },
              headCells: {
                style: {
                  '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                  },
                },
              },
              cells: {
                style: {
                  '&': {
                      borderRightStyle: 'solid',
                      borderRightWidth: '1px',
                      borderRightColor: defaultThemes.default.divider.default,
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      fontSize: '10px',
                      lineHeight: '140%',
                  }
                },
              },
              rows: {
                style: {
                    minHeight: 'auto', // override the row height
                    //minHeight: '250px', // override the row height
                },
            },
          };

          createTheme('solarized', {
            context: {
              background: '#cb4b16',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });

        const paginationComponentOptions = {
            rowsPerPageText: 'Строк на странице',
            rangeSeparatorText: 'из',
        };

        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-9">
                            <h1>Товары </h1>
                        </div>
                    </div>
                </div>
                <div className="grid_top">
                    <div className="row">
                       <div className="date_input date_from">
                            <DatePicker2  onDayChange={this.onStartChange} selectedDays={this.state.startDate}></DatePicker2>
                        </div>
                        <div className="date_input date_to">
                            <DatePicker2  onDayChange={this.onEndChange} selectedDays={this.state.endDate}></DatePicker2>
                        </div>
                        <div>
                            <button onClick={this.onResetClick} className="reset_btn">
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                        <div className="grid_top__btn-box">
                            <button onClick={this.onSearchClick} className="btn btn_black">Искать</button>
                        </div>
                        <div>
                            <a href="#" onClick={this.onOpenAdd} className="btn grid_top_add-btn" style={{ 'float': 'right'}}>
                                <AddIcon></AddIcon>Добавить
                            </a>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <PerfectScrollbar>
                       <DataTable
                            sortable={true}
                            noHeader={true}
                            columns={columns}
                            theme="solarized"
                            data={this.state.data}
                            customStyles={customStyles}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={this.state.tableHeight}
                            responsive
                        />
                    </PerfectScrollbar>
                </div>
                <Popup open={this.state.id !== null} onClose={this.onCloseEdit} >
                    {
                        this.state.id && 
                        <div className="edit_content">
                            <button className="close" onClick={this.onCloseEdit}>
                                <CloseIcon></CloseIcon>
                            </button>
                            <PerfectScrollbar>
                               <Edit _id={this.state.id}></Edit>
                            </PerfectScrollbar>
                        </div>
                    }
                </Popup>
                <Popup open={this.state.add} onClose={this.onCloseAdd} >
                    {
                        this.state.add && 
                        <div className="edit_content">
                            <button className="close" onClick={this.onCloseAdd}>
                                <CloseIcon></CloseIcon>
                            </button>
                            <PerfectScrollbar>
                               <Add></Add>
                            </PerfectScrollbar>
                        </div>
                    }
                </Popup>
            </div>
        )
    }
}