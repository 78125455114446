import React,{ useState, useEffect} from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import config from '../../config';
import Legals from '../managers/legals/legals';
import Sites from '../managers/sites/sites';
import Phones from '../managers/phones/phones';
import headers from "../../auth/headers";
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import '../managers/manager.css';

const ManagerProfile = ({ match }) => {

    
    let languages = [
        { value: 'ru', label: 'Русский' },
        { value: 'uk', label: 'Український' },
        { value: 'en', label: 'English' },
    ]
    
    const [language, setLanguage] = useState(languages[0]);

    const onLanguageChange = (e)=>{
        setLanguage(e);
    }

    const [data, setData] = React.useState(null);
    const [changeLogo, setChangeLogo] = React.useState(false);
    const [showIframe, setShowIframe] = React.useState(false);
    const iFrameCode = data ? `<iframe src="${config.editor}editor/${data._id}/${language.value}"></iframe>` : null;

    const fetch = ()=>{
        let profile = localStorage.getItem("profile");
        if(profile){
            profile = JSON.parse(profile);
            axios.get(`${config.api}managers/get?id=${profile.manager_id}`, headers)
            .then(res => {
                setData(res.data);
            }).catch((err => {
            
            }));
        }
    }

    useEffect(()=>{
        fetch();
    }, [])


    const [images, setImages] = React.useState([]);
    const onChange = (imageList, addUpdateIndex) => {
        setChangeLogo(true);
        setImages(imageList);
    };

    const onShowIframe = ()=>{
        setShowIframe(!showIframe);
    }

    const onResetPassword = ()=>{
        axios.post(`${config.api}managers/reset-password`, { email: data.email2}, headers)
        .then(res => {
            toast.success("Новый пароль отправлен Вам на почту ", {
                position: toast.POSITION.TOP_CENTER
            });
        }).catch((err => {
             console.log(err)
        }));
    }

    return (
        <div className="content_body">
            <div className="content_top">
               <h1>Профиль</h1>
            </div>
            <div className="admin-wrapper">
                {
                    data && 
                    <div className="block">
                    <Formik
                            initialValues={{ firstname: data.firstname, phone: data.phone , email: data.email, lastname: data.lastname, delivery_address: data.delivery_address, email2: data.email2, max_credit: data.max_credit, balance: data.balance, file_save_days: data.file_save_days,  allow_iframe: data.allow_iframe,  bonus: data.bonus, aprooved: data.aprooved}}
                            onSubmit={values => {

                                values._id = data._id;

                                if(images.length && changeLogo){
                                    let base64 = images[0]['data_url']
                                    values.logo = base64;
                                }else{
                                    values.logo = data.logo;
                                }

                                values.changeLogo = changeLogo;

                                axios.post(`${config.api}managers/update`, values, headers) 
                                .then(res => {
                                    toast.success("Сохранено", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }).catch((err => {
                                    toast.error("Ошибка сохранения", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }));
                            }}
                            validationSchema={Yup.object().shape({
                                email2: Yup.string().email().required('Обязательно'),
                                email: Yup.string().email().required('Обязательно'),
                                firstname: Yup.string().required('Обязательно'),
                                phone: Yup.string().required('Обязательно'),
                                lastname: Yup.string().required('Обязательно'),
                                delivery_address: Yup.string().required('Обязательно'),
                                max_credit: Yup.number().required('Обязательно'),
                                balance: Yup.number().required('Обязательно'),
                                file_save_days: Yup.number().required('Обязательно'),
                                bonus: Yup.number().required('Обязательно')
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="b-form">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Имя * </label>
                                                    <input
                                                        type="text"
                                                        value={values.firstname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="firstname"
                                                        placeholder="Имя"
                                                        className={
                                                            errors.firstname && touched.firstname
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.firstname && touched.firstname && (
                                                        <div className="form_error">{errors.firstname}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Номер телефона владельца аккаунта * </label>
                                                    <input
                                                        type="text"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="phone"
                                                        placeholder="+8888888888"
                                                        className={
                                                            errors.phone && touched.phone
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="form_error">{errors.phone}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Почта регистрации * </label>
                                                    <input
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="email"
                                                        readOnly={true}
                                                        placeholder="john@gmail.com"
                                                        className={
                                                            errors.email && touched.email
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="form_error">{errors.email}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Срок хранения файлов (дней)</label>
                                                    <input
                                                        type="number"
                                                        value={values.file_save_days}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="file_save_days"
                                                        placeholder="0"
                                                        min="0"
                                                        className={
                                                            errors.file_save_days && touched.file_save_days
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.file_save_days && touched.file_save_days && (
                                                        <div className="form_error">{errors.file_save_days}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Бонус менеджера</label>
                                                    <input
                                                        type="number"
                                                        value={values.bonus}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="bonus"
                                                        placeholder="0"
                                                        min="0"
                                                        className={
                                                            errors.bonus && touched.bonus
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.bonus && touched.bonus && (
                                                        <div className="form_error">{errors.bonus}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Фамилия * </label>
                                                    <input
                                                        type="text"
                                                        value={values.lastname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="lastname"
                                                        placeholder="Snow"
                                                        className={
                                                            errors.lastname && touched.lastname
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.lastname && touched.lastname && (
                                                        <div className="form_error">{errors.lastname}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Почта владельца аккаунта * </label>
                                                    <input
                                                        type="text"
                                                        value={values.email2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="email2"
                                                        placeholder="john@gmail.com"
                                                        className={
                                                            errors.email2 && touched.email2
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.email2 && touched.email2 && (
                                                        <div className="form_error">{errors.email2}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Адрес доставки готовой продукции</label>
                                                    <input
                                                        type="text"
                                                        value={values.delivery_address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="delivery_address"
                                                        placeholder="N.Y"
                                                        className={
                                                            errors.delivery_address && touched.delivery_address
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.delivery_address && touched.delivery_address && (
                                                        <div className="form_error">{errors.delivery_address}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Максимальный кредитный лимит</label>
                                                    <input
                                                        type="number"
                                                        value={values.max_credit}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="max_credit"
                                                        readOnly={true}
                                                        placeholder="0"
                                                        min="0"
                                                        className={
                                                            errors.max_credit && touched.max_credit
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.max_credit && touched.max_credit && (
                                                        <div className="form_error">{errors.max_credit}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Баланс</label>
                                                    <input
                                                        type="number"
                                                        value={values.balance}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        readOnly={true}
                                                        name="balance"
                                                        placeholder="0"
                                                        min="0"
                                                        className={
                                                            errors.balance && touched.balance
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.balance && touched.balance && (
                                                        <div className="form_error">{errors.balance}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <button className="save_button">Сохранить</button>
                                        </div>
                                </form>
                                );
                            }}
                        </Formik>
                </div>

                }
                <div className="block">
                {
                        (data != null) && (data.logo) && (!changeLogo) &&
                            <div className="row">
                                <div className="col-2">
                                <div className="form_group">
                                    <label>Лого</label>
                                    <div className="logo_wrapper">
                                        <img src={config.api + data.logo} alt="img"></img>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <button className="btn" onClick={()=>{ setChangeLogo(true)}}>Изменить</button>
                                </div>
                                </div>
                            </div>
                        }
                        {
                            (data != null) && (!data.logo || changeLogo) &&
                            <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={1}
                                    dataURLKey="data_url"
                                >
                            {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps
                            }) => (
    
                            <div className="upload__image-wrapper">
                                <div className="row">
                                        <div className="col-8">
                                            <div className="form_group">
                                                <label>Лого</label>
                                                {
                                                    imageList.length < 1 &&
                                                    <button
                                                        className="btn btn_black"
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                    >
                                                        Выбрать файл
                                                    </button>
                                                }
                                                
                                                {
                                                    imageList.map((image, index) => (
                                                        <div key={index} className="row">
                                                            <div className="col-6">
                                                                <div className="logo_wrapper">
                                                                    <img src={image.data_url} alt="img"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="image-item__btn-wrapper">
                                                                    <div className="form_group">
                                                                        <button onClick={() => onImageUpdate(index)}  className="btn btn_black">Обновить</button>
                                                                    </div>
                                                                    <div className="form_group">
                                                                        <button onClick={() => onImageRemove(index)}  className="btn btn_black">Удалить</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            )}
                        </ImageUploading>
                        }
                </div>
                {
                    data &&
                    <div className="block">
                        <div className="row">
                            <div className="col-4">
                                <div className="form_group">
                                    <label>Изменить пароль</label>
                                    <input type="text" value={data.email} readOnly></input>
                                </div>
                                <button onClick={onResetPassword} className="btn btn_black">Сбросить пароль</button>
                            </div>
                        </div>
                    </div>
                }
                <div  className="block">
                <div className="row">
                        <div className="col-6">
                            <div className="iframe_block">
                            <button className="iframe_button" onClick={onShowIframe}>Создать постоянный код iFrame для сайта</button>
                            {
                                showIframe &&
                                <div className="iframe_code">
                                    <textarea value={iFrameCode} rows={5} readOnly={true}></textarea>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-2">
                        <Select value={language} options={languages} onChange={onLanguageChange}></Select>
                    </div>
                    </div>
                </div>
                {
                    data &&
                    <Phones _id={data._id}></Phones>
                }
                {
                    data &&
                    <Sites _id={data._id}></Sites>
                }
                {
                    data &&
                    <Legals _id={data._id}></Legals>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    >
                </ToastContainer>
            </div>
        </div>
      
    )
}

export default ManagerProfile;
