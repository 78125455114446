import React, { useEffect } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import Select from 'react-select';
import axios from "axios";
import config from '../../config';
import headers from "../../auth/headers";
import { ToastContainer, toast } from 'react-toastify';
import { categories } from './categories';
import { langs } from '../../langs';
import RichEditor from '../richEditor/richEditor';
import './news.css';
import {ReactComponent as ArrowIcon} from "../../assets/back.svg";
import {useHistory} from "react-router-dom";

const Edit = ({ match }) => {

    const [data, setData] = React.useState(null);
    const [changeLogo, setChangeLogo] = React.useState(false);
    const [images, setImages] = React.useState([]);

    const onChange = (imageList) => {
      setImages(imageList);
    };

    const [content, setContent] = React.useState("");
    const onChangeContent = (html) => {
        setContent(html);
    };

    const fetch = ()=>{
        axios.get(`${config.api}site/get-new?id=${match.params.id}`, headers)
        .then(res => {
            let responseData = res.data;
            categories.forEach((cat)=>{
                if(cat.value === responseData.category){
                    responseData.category = cat;
                }
            });
            langs.forEach((cat)=>{
                if(cat.value === responseData.lang){
                    responseData.lang = cat;
                }
            });
            setContent(res.data.content);
            setData(res.data);
        });
    }

    useEffect(()=>{
        fetch();
    }, []);

    const history = useHistory();

    return (
        <div className="content_body">
            <button className="back_button" onClick={() => history.goBack()}>
                <ArrowIcon></ArrowIcon>
            </button>
            <h1>Редактировать новость</h1>
            <div className="modal_block">
            {
                    (data != null) && (data.image) && (!changeLogo) &&
                    <div className="row">
                            <div className="col-4">
                               <div className="form_group">
                                   <label>Картинка превью для новости</label>
                                   <div className="logo_wrapper">
                                       <img src={config.api + data.image}></img>
                                   </div>
                               </div>
                               <div className="form_group">
                                  <button className="btn btn_black" onClick={()=>{ setChangeLogo(true)}}>Изменить</button>
                               </div>
                            </div>
                    </div>
                }
                {
                         (data != null) && (!data.image || changeLogo) &&
                         <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={1}
                                dataURLKey="data_url"
                            >
                         {({
                             imageList,
                             onImageUpload,
                             onImageUpdate,
                             onImageRemove,
                             dragProps
                         }) => (
 
                         <div className="upload__image-wrapper">

                                        <div className="form_group">
                                            {
                                                imageList.length < 1 &&
                                                <button
                                                    className="btn btn_black"
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Загрузить картинку
                                                </button>
                                            }
                                            
                                            {
                                                imageList.map((image, index) => (
                                                    <div key={index} className="image-item row">
                                                        <div className="col-4">
                                                            <div className="logo_wrapper">
                                                                <img src={image.data_url} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="image-item__btn-wrapper">
                                                                <div className="form_group">
                                                                    <button onClick={() => onImageUpdate(index)}  className="btn btn_black">Обновить</button>
                                                                </div>
                                                                <div className="form_group">
                                                                    <button onClick={() => onImageRemove(index)}  className="btn btn_black">Удалить</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                         </div>
                         )}
                     </ImageUploading>
                     }
                    {
                        data && 
                        <Formik
                        initialValues={{ title: data.title, short_content: data.short_content, order: data.order, category: data.category, lang: data.lang }}
                        onSubmit={values => {
                            if(images.length){
                                let base64 = images[0]['data_url']
                                values.image = base64;
                            }else{
                                values.image = data.image;
                            }

                            values._id = data._id;
                            values.category = values.category.value;
                            values.lang = values.lang.value;
                            values.content = content;
                            axios.post(`${config.api}site/update-news`, values, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            });
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required('Обязательно'),
                            order: Yup.number().required('Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-9">
                                            <div className="form_group">
                                                <label>Заголовок * </label>
                                                <input
                                                    type="text"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title"
                                                    placeholder=""
                                                    className={
                                                        errors.title && touched.title
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title && touched.title && (
                                                    <div className="form_error">{errors.title}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Краткое содержание * </label>
                                                <textarea
                                                    style={{
                                                        border: '1px solid #DDDEE0',
                                                        boxsizing: 'border-box',
                                                        width: '100%',
                                                        borderradius: '16px',
                                                        padding: '15px',
                                                        height: '100px'
                                                        
                                                    }}
                                                    type="text"
                                                    maxlength="300"
                                                    value={values.short_content}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="short_content"
                                                    placeholder=""
                                                    className={
                                                        errors.short_content && touched.short_content
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.short_content && touched.short_content && (
                                                    <div className="form_error">{errors.short_content}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Контент * </label>
                                                <RichEditor onChangeContent={onChangeContent} content={content}></RichEditor>
                                            </div>
                                            <div className="form_group">
                                                <label>Категория </label>
                                                <Select options={categories} value={values.category} onChange={option => setFieldValue("category", option)}/>
                                                {errors.category && touched.category && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Язык </label>
                                                <Select options={langs} value={values.lang} onChange={option => setFieldValue("lang", option)}/>
                                                {errors.lang && touched.lang && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Приоритет статьи</label>
                                                <input
                                                    type="text"
                                                    value={values.order}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="order"
                                                    placeholder=""
                                                    className={
                                                        errors.order && touched.order
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.order && touched.order && (
                                                    <div className="form_error">{errors.order}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <button className="save_button">Сохранить</button>
                                            </div>
                                        </div>

                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
                    }
                    <ToastContainer></ToastContainer>
            </div>
        </div>
      
    )
}

export default Edit;
