import React,{ useEffect } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";
import { ToastContainer, toast } from 'react-toastify';
import '../client.css';

const EditClient = ({ _id }) => {

    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}clients/get?id=${_id}`, headers)
        .then(res => {
            setData(res.data);
        }).catch((err => {
        
        }));
    }

    useEffect(()=>{
        fetch();
    }, [])

    return (
            <div className="popup">
                <h2 className="popup__title">Редактировать клиента</h2>
                <div className="popup__inner">
                        {
                            data && 
                            <Formik
                            initialValues={{ firstname: data.firstname, phone: data.phone, email: data.email, lastname: data.lastname, company: data.company, comment: data.comment}}
                            onSubmit={values => {

                                values._id = data._id;

                                axios.post(`${config.api}clients/update`, values, headers)
                                .then(res => {
                                    toast.success("Сохранено", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }).catch((err => {
                                    toast.error("Ошибка сохранения", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }));
                            }}
                            validationSchema={Yup.object().shape({
                                firstname: Yup.string().required('Обязательно'),
                                lastname: Yup.string().required('Обязательно'),
                                email: Yup.string().email().required('Обязательно'),
                                phone: Yup.string().required('Обязательно')
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="b-form">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Имя * </label>
                                                    <input
                                                        type="text"
                                                        value={values.firstname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="firstname"
                                                        placeholder="Іван"
                                                        className={
                                                            errors.firstname && touched.firstname
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.firstname && touched.firstname && (
                                                        <div className="form_error">{errors.firstname}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Номер телефона владельца аккаунта * </label>
                                                    <input
                                                        type="text"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="phone"
                                                        placeholder="+8888888888"
                                                        className={
                                                            errors.phone && touched.phone
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="form_error">{errors.phone}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Почта регистрации * </label>
                                                    <input
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="email"
                                                        placeholder="john@gmail.com"
                                                        className={
                                                            errors.email && touched.email
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="form_error">{errors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Фамилия * </label>
                                                    <input
                                                        type="text"
                                                        value={values.lastname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="lastname"
                                                        placeholder="Snow"
                                                        className={
                                                            errors.lastname && touched.lastname
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.lastname && touched.lastname && (
                                                        <div className="form_error">{errors.lastname}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                        <label>Компания</label>
                                                        <input
                                                            type="text"
                                                            value={values.company}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="company"
                                                            placeholder=""
                                                            className={
                                                                errors.company && touched.company
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                            }
                                                        />
                                                        {errors.company && touched.company && (
                                                            <div className="form_error">{errors.company}</div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form_group">
                                                    <label>Комментарий</label>
                                                    <textarea
                                                            value={values.comment}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="comment"
                                                            placeholder=""
                                                            className={
                                                                errors.comment && touched.comment
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                            }
                                                    ></textarea>
                                                    {errors.comment && touched.comment && (
                                                            <div className="form_error">{errors.comment}</div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order_popup_bottom">
                                            <div className="row">
                                                <div className="col-4">
                                                </div>
                                                <div className="col-4">
                                                    <button className="order_button">Отправить</button>
                                                </div>
                                            </div>
                                        </div>

                                </form>
                                );
                            }}
                        </Formik>
                        }
                </div>
                <ToastContainer></ToastContainer>
            </div>
    )
}

export default EditClient;
