import React  from 'react';
import { Formik } from "formik";
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";
import * as Yup from "yup";

const EditPhone = ({ data, onCloseEdit }) => {
    return (
        <div>
            <div className="row">
                <div className="col-6 add_block">
                    <Formik
                        initialValues={{ phone: data.phone, name: data.name }}
                        onSubmit={values => {
                            values._id = data._id;
                            axios.post(`${config.api}managers/update-phone`, values, headers)
                            .then(res => {
                                onCloseEdit();
                            }).catch((err => {
                                onCloseEdit();
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            phone: Yup.string().required('Обязательно'),
                            name: Yup.string().required('Обязательно'),
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                    <div className="form_group">
                                                <label>Номер телефона *</label>
                                                <input
                                                    type="text"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone"
                                                    placeholder="+38 099 123 45 67"
                                                    className={
                                                        errors.phone && touched.phone
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="form_error">{errors.phone}</div>
                                                )}
                                            </div>
                                    <div className="form_group">
                                                <label>Имя *</label>
                                                <input
                                                    type="text"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="name"
                                                    placeholder="Имя"
                                                    className={
                                                        errors.name && touched.name
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.name && touched.name && (
                                                    <div className="form_error">{errors.name}</div>
                                                )}
                                            </div>
                                    <div className="form_group">
                                        <button className="save_button">Сохранить</button>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default EditPhone;
