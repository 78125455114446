import React from 'react';
import { useParams } from 'react-router-dom';
const Autorize = ({ }) => {
    const params = useParams()
    if(params.t){
        localStorage.setItem('TOKEN',params.t);
        localStorage.setItem('profile', params.p);
        window.location.href = '/manager-orders'
    }
    return (
        <div className="login_block">

        </div>
    )
}

export default Autorize;


