import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/delete_red.svg';
import { ReactComponent as PenIcon } from '../../../assets/pen.svg';
import Color from './color';
import ColorEdit from './colorEdit';
import './colors.css'

export default class Colors extends React.Component{
    constructor(props){
        super(props);
        this.state = { add: false, colors: [], edit: null };
        this.onDelete = this.onDelete.bind(this);
        this.onAddOpen = this.onAddOpen.bind(this);
        this.onAddClose = this.onAddClose.bind(this);
        this.addColor = this.addColor.bind(this);
        this.onEditOpen = this.onEditOpen.bind(this);
        this.onEditClose = this.onEditClose.bind(this);
        this.updateColor = this.updateColor.bind(this);
    }
    componentDidMount(){
        if(this.props.colors){
            this.setState({ colors: this.props.colors });
        }
    }
    UNSAFE_componentWillReceiveProps(){
        if((this.props.colors) && (this.props.colors.length && !this.state.colors.length)){
            this.setState({ colors: this.props.colors });
        }

    }
    addColor(data){
        let colors = this.state.colors;
        colors.push(data);
        this.setState({ colors: colors, add: false });
        this.props.onColorsChange(colors);
    }
    onAddOpen(){
        this.setState({  add: !this.state.add });
    }
    onAddClose(){
        this.setState({  add: false });
    }
    onDelete(e){
        let colors = this.state.colors.filter((c)=>{ return c.id !== e.target.value });
        this.setState({ colors: colors });
        this.props.onColorsChange(colors);
    }
    onEditOpen(e){
        let color = this.state.colors.filter((c)=>{ return c.id == e.target.value })[0];
        this.setState({  edit: color });
    }
    onEditClose(){
        this.setState({  edit: null });
    }
    updateColor(color){
        let colors = this.state.colors;
        for(let i = 0; i < colors.length; i++){
            if(colors[i]['id'] == color.id){
                colors[i] = color;
            }
        }
        this.setState({ colors: colors, edit: null });
    }
    render(){
        let colors = this.state.colors.map((color, i)=>{
            return(
                <div key={i} className="color_item">
                    <div className="row">
                        <div className="col-2">
                            <p>{i+1}</p>
                        </div>
                        <div className="col-2">
                            <div className="color_hex">{color.hex}</div>
                        </div> 
                        <div className="col-2">
                            <div className="color_circule" style={{ background: color.hex }}></div>
                        </div> 
                        <div className="col-2">
                            <div className="price">{color.price} грн</div>
                        </div> 
                        <div className="col-4 edit-btns-box">
                            <div className="button_clickable">
                                <button value={color.id} onClick={this.onEditOpen}></button>
                                <PenIcon></PenIcon>
                            </div>
                            <div className="button_clickable">
                                <button value={color.id} onClick={this.onDelete}></button>
                                <DeleteIcon></DeleteIcon>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return(
            <div className="colors_box">
                <div className="form_group">
                    <label>Цвета</label>
                </div>
                <div className="colors_list">
                   {
                       colors
                   }
                </div>
                <div className="add_color_row">
                    <button className="btn btn_black" onClick={this.onAddOpen}>{this.state.add ? "Отменить" : "Добавить цвет" }</button>
                </div>
                {
                    this.state.add &&
                    <Color addColor={this.addColor}></Color>
                }
                {
                    this.state.edit &&
                    <ColorEdit edit={this.state.edit} updateColor={this.updateColor}></ColorEdit>
                }
            </div>
        )
    }
}