import React,{ useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from '../../config';
import { addToken, addProfile } from '../../actions/index';
import { connect } from 'react-redux'
import './login.css';

const Login = ({ state, addProfile, addToken }) => {

    const [errorMessage, setErrorMessage] = useState(null);

    return (
        <div className="login_block">

            <div className="modal_block">
                 
                  <Formik
                        initialValues={{ email: "", password: "", not_robot: false}}
                        onSubmit={values => {
                            axios.post(`${config.api}managers/singin`, values)
                            .then(res => {
                                if (res.data) {
                                    addProfile(res.data.profile);
                                    addToken(res.data.token);
                                    localStorage.setItem('TOKEN', res.data.token);
                                    localStorage.setItem('profile', JSON.stringify(res.data.profile));
                                    if(res.data.profile.role === 'admin'){
                                        window.location.href = '/managers';
                                    }if(res.data.profile.role === 'manager'){
                                        window.location.href = '/manager-orders';
                                    }
                                }else{
                                    setErrorMessage("Error");
                                }
                            }).catch((err => {
                                if((err.response) && (err.response.data === 'not aprooved')){
                                    setErrorMessage("Пользователь не подтвержден");
                                }else{
                                    setErrorMessage("Error");
                                }
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email().required('Обязательно'),
                            password: Yup.string().required('Обязательно'),
                            not_robot: Yup.bool().oneOf([true], 'Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <div className="s-form-box">
                                    <form onSubmit={handleSubmit} className="s-form-box__form">
                                        <div className="s-form-box__input-box">
                                            <label className="s-form-box__label">Email</label>
                                            <input
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                placeholder='Введите email'
                                                className="s-form-box__input first__input"
                                            />
                                            {errors.email && touched.email && (
                                                <div className="form_error">{errors.email}</div>
                                            )}
                                        </div>
                                        <div className="s-form-box__input-box">
                                            <label className="s-form-box__label" >Пароль</label>
                                            <input
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="password"
                                                placeholder='Введите пароль'
                                                className="s-form-box__input first__input"
                                            />
                                            {errors.password && touched.password && (
                                                <div className="form_error">{errors.password}</div>
                                            )}
                                        </div>
                                        <div className="s-form-box__add-box">
                                            <div className="s-form-box__link-box">
                                                <a className="s-text-reg s-form-box__link" href="/register">Зарегистрироваться</a>
                                                <a className="s-text-reg s-form-box__link" href="/forgot">Забыли пароль?</a>
                                            </div>

                                            <label className="s-form-box__check-label">
                                                <Field type="checkbox" name="not_robot" className="s-form-box__checkbox" />
                                                <span className="s-form-box__custom-checkbox"></span>
                                                Я не робот
                                            </label>
                                            {errors.not_robot && touched.not_robot && (
                                                <div className="form_error">{errors.not_robot}</div>
                                            )}
                                        </div>
                                        <div className="s-form-box__input-box">
                                            <button className="save_button s-btn s-btn_narrow s-btn_black s-form-box__btn first__submit">Вход</button>
                                        </div>
                                        {/*<div className="form_group">
                                            <label>Email</label>
                                            <input
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                placeholder="admin@gmail.com"
                                                className={
                                                    errors.email && touched.email
                                                    ? "text-input error b-form__input"
                                                    : "text-input b-form__input"
                                                }
                                            />
                                            {errors.email && touched.email && (
                                                <div className="form_error">{errors.email}</div>
                                            )}
                                         </div>
                                         <div className="form_group">
                                            <label>Password</label>
                                            <input
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="password"
                                                placeholder="John"
                                                className={
                                                    errors.password && touched.password
                                                    ? "text-input error b-form__input"
                                                    : "text-input b-form__input"
                                                }
                                            />
                                            {errors.password && touched.password && (
                                                <div className="form_error">{errors.password}</div>
                                            )}
                                        </div>
                                        <div className="form_group">
                                            <button className="save_button">Login</button>
                                        </div>
                                        {
                                            errorMessage &&
                                            <div className="form_group">
                                                <div className="form_error">{errorMessage}</div>
                                            </div>
                                        }*/}

                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
            </div>
        </div>
      
    )
}

const mapStateToProps = (state) => {
    return {
        state: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProfile: (obj) => dispatch(addProfile(obj)),
        addToken: (obj) => dispatch(addToken(obj)),
    };
 };

export default connect(mapStateToProps, mapDispatchToProps)(Login);

