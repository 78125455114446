import React from 'react';
import { Formik } from "formik";
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";
import * as Yup from "yup";

const AddSite = ({ _id, onCloseAdd }) => {
    return (
        <div className="row">
            <div className="col-6 add_block">
                <Formik
                            initialValues={{ url: "" }}
                            onSubmit={values => {
                                values.manager_id = _id;
                                axios.post(`${config.api}managers/create-site`, values, headers)
                                .then(res => {
                                    onCloseAdd();
                                }).catch((err => {
                                    onCloseAdd();
                                }));
                            }}
                            validationSchema={Yup.object().shape({
                                url: Yup.string().required('Обязательно')
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="b-form">
                                                <div className="form_group">
                                                    <label>URL *</label>
                                                    <input
                                                        type="text"
                                                        value={values.url}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="url"
                                                        placeholder="www.domain.com"
                                                        className={
                                                            errors.url && touched.url
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.url && touched.url && (
                                                        <div className="form_error">{errors.url}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <button className="save_button">Сохранить</button>
                                                </div>
                                    </form>
                                );
                            }}
                        </Formik>
            </div>
        </div>
    )
}

export default AddSite;
