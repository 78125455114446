import React from 'react';
import { ReactComponent as ProfileIcon } from '../../assets/profile.svg';
import { ReactComponent as CartIcon } from '../../assets/cart.svg';
import { ReactComponent as OrderIcon } from '../../assets/order.svg';
import { ReactComponent as ClientIcon } from '../../assets/client.svg';
import { ReactComponent as BagtIcon } from '../../assets/bag.svg';
import { ReactComponent as InfoIcon } from '../../assets/info_black.svg';
import Auth from '../../auth/auth';
import './navigation.scss';

export default class Navigation extends React.Component{
    constructor(props){
        super(props);
    }
    onLogout(){
        if (window.confirm("Вы действительно хотите выйти?")) {
            localStorage.removeItem("profile");
            localStorage.removeItem("TOKEN");
            window.location.href = '/login';
        }
    }
    render(){
        let urlParts = window.location.href.split('/');
        let url = urlParts.length ? urlParts[urlParts.length-1] : null;
        return(
            <div className="navigation">
               <div className="menu">
                   {
                       Auth.isAdmin() &&
                       <ul>
                            <li><a href="/managers" className={url == "managers" ? "active" : ""}>Менеджеры <ProfileIcon></ProfileIcon></a></li>
                            <li><a href="/orders" className={url == "orders" ? "active" : ""}>Заказы менеджеров<CartIcon></CartIcon></a></li>
                            <li><a href="/clients" className={url == "clients" ? "active" : ""}>Клиенты менеджеров<ClientIcon></ClientIcon></a></li>
                            <li><a href="/own-orders" className={url == "own-orders" ? "active" : ""}>Заказы Сувенирного<OrderIcon></OrderIcon></a></li>
                            <li><a href="/products" className={url == "products" ? "active" : ""}>Товары<BagtIcon></BagtIcon></a></li>
                            <li><a href="/pages" className={url == "pages" ? "active" : ""}>Страницы<ClientIcon></ClientIcon></a></li>
                            <li><a href="#" onClick={this.onLogout}>Выйти</a></li>
                        </ul>
                   }
                   {
                       !Auth.isAdmin() &&
                       <ul>
                           <li><a href="/manager-profile" className={url == "profile" ? "active" : ""}>Профиль <ProfileIcon></ProfileIcon></a></li>
                           <li><a href="/manager-orders" className={url == "manager-orders" ? "active" : ""}>Заказы<CartIcon></CartIcon></a></li>
                           <li><a href="/manager-clients" className={url == "manager-clients" ? "active" : ""}>Клиенты<ClientIcon></ClientIcon></a></li>
                           <li><a href="/support" className={url == "support" ? "active" : ""}>Служба поддержки<InfoIcon></InfoIcon></a></li>
                           <li><a href="#" onClick={this.onLogout}>Выйти</a></li>
                       </ul>
                   }
               </div>
            </div>
        )
    }
}