import React from "react";
import { ReactComponent as PenIcon } from '../../assets/pen.svg';
import './pages.scss';

export default class Pages extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
       return(
        <div className="content_body">
            <h1>Страницы</h1>
            <ul className="pages_list">
                <li>
                    <div className="row">
                        <div className="col-1">
                            <h6 className="pages_number">01</h6>
                        </div>
                        <div className="col-8">
                            <h5 className="pages_header">Главная страница</h5>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <a className="pages_edit-btn" href="/main-page-edit">
                                <PenIcon></PenIcon>
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-1">
                            <h6 className="pages_number">02</h6>
                        </div>
                        <div className="col-8">
                            <h5 className="pages_header">Новости</h5>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <a className="pages_edit-btn" href="/news">
                                <PenIcon></PenIcon>
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-1">
                            <h6 className="pages_number">03</h6>
                        </div>
                        <div className="col-8">
                            <h5 className="pages_header">Обучение</h5>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <a className="pages_edit-btn" href="/lessons">
                                <PenIcon></PenIcon>
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-1">
                            <h6 className="pages_number">04</h6>
                        </div>
                        <div className="col-8">
                            <h5 className="pages_header">Контакты</h5>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <a className="pages_edit-btn" href="/contacts">
                                <PenIcon></PenIcon>
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-1">
                            <h6 className="pages_number">05</h6>
                        </div>
                        <div className="col-8">
                            <h5 className="pages_header">Идеи</h5>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <a className="pages_edit-btn" href="/ideas">
                                <PenIcon></PenIcon>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
       )
    }
}