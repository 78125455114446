import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Managers from './managers/managers';
import Add from './managers/add/add';
import Auth from '../auth/auth';
import Products from './products/products';
import Clients from './clients/clients';
import Orders from './orders/orders';
import ManagerOrders from './managerOrders/orders';
import Lessons from './lessons/lessons';
import LessonAdd from './lessons/addLesson';
import LessonEdit from './lessons/editLesson';
import News from './news/news';
import NewsAdd from './news/addNews';
import NewsEdit from './news/editNews';
import ManagerClients from './managerClients/managerClients';
import ManagerProfile from './managerProfile/managerProfile';
import OwnOrders from './ownOrders/ownOrders';
import Login from './login/login';
import Register from './register/register';
import Tempaltes from './template/templates';
import Support from './support/support';
import Pages from './pages/pages';
import MainPage from './mainPage/mainPage';
import Contacts from './contacts/contacts';
import Ideas from './ideas/ideas';
import IdeaAdd from './ideas/addIdea';
import EditIdea from './ideas/editIdea';
import Header from './header/header';
import Autorize from './autorize/autorize';
import { ProtectedRoute } from '../auth/protectedRoute';
import axios from "axios";
import { loadReCaptcha } from 'react-recaptcha-google'
import './common.scss';
import './app.scss';

axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response && error.response.status) {
      const status = error.response.status;
      if (status === 403) {
        localStorage.removeItem("TOKEN");
        window.location.href = '/login'
      }
    }
    return Promise.reject(error);
});


const  App = ()=>{

  const [mobile, setMobile] = useState(false);

  useEffect(()=>{
    loadReCaptcha();
    
    function hideAdminOnMobile() {
      if (window.innerWidth < 1200) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    hideAdminOnMobile();

    function handleResize() {
      hideAdminOnMobile();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }

  }, []);

  return (
    <div>
    
            {mobile && 

              <div className="hide-mobile">
                <div className="hide-mobile__text-box">
                  <h1 className="hide-mobile__title">Уважаемые пользователи!</h1>
                  <span className="hide-mobile__text">
                    Для просмотра админ панели, пожалуйста, используйте устройство с большим экраном (компьютер, ноутбук)
                  </span>
                </div>
              </div>

            }

            <Header/>

            <Router>
                <Switch>
             
                    <ProtectedRoute path="/managers/add" component={Add} />
                    <ProtectedRoute path="/news/edit/:id" component={NewsEdit} />
                    <ProtectedRoute path="/news/add" component={NewsAdd} />
                    <ProtectedRoute path="/news" component={News} />
                    <ProtectedRoute path="/lessons/edit/:id" component={LessonEdit} />
                    <ProtectedRoute path="/lessons/add" component={LessonAdd} />
                    <ProtectedRoute path="/lessons" component={Lessons} />
                    <ProtectedRoute path="/contacts" component={Contacts} />
                    <ProtectedRoute path="/managers" component={Managers} />
                    <ProtectedRoute path="/products/template/:id" component={Tempaltes} />
                    <ProtectedRoute path="/products" component={Products} />
                    <ProtectedRoute path="/clients" component={Clients} />
                    <ProtectedRoute path="/orders" component={Orders} />
                    <ProtectedRoute path="/own-orders" component={OwnOrders} />
                    <ProtectedRoute path="/manager-orders" component={ManagerOrders} />
                    <ProtectedRoute path="/manager-clients" component={ManagerClients} />
                    <ProtectedRoute path="/manager-profile" component={ManagerProfile} />
                    <ProtectedRoute path="/support" component={Support} />
                    <ProtectedRoute path="/pages" component={Pages} />
                    <ProtectedRoute path="/main-page-edit" component={MainPage} />
                    <ProtectedRoute path="/ideas/edit/:id" component={EditIdea} />
                    <ProtectedRoute path="/ideas/add" component={IdeaAdd} />
                    <ProtectedRoute path="/ideas" component={Ideas} />
                    <Route path="/login" component={Login} />
                    <Route path="/autorize/:t/:p" component={Autorize} />
                    <Route path="/register" component={Register} />
                    <Route path="/" render={(props) =>{
                      if (Auth.isAdmin()) {
                        return (<Redirect to={{ pathname: '/managers', state: { from: props.location } }} />)
                      } else if (!Auth.isAdmin()) {
                        return ( <Redirect to={{ pathname: '/manager-orders', state: { from: props.location } }} />)
                      }
                      }} />
                </Switch>
            </Router>
        </div>
    )
}

export default App;