import React, { useEffect, useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import axios from "axios";
import config from '../../config';
import headers from "../../auth/headers";
import { ToastContainer, toast } from 'react-toastify';
import './contacts.css';

const Edit = ({ lang }) => {

    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}site/get-footer?lang=${lang}`, headers)
        .then(res => {
            setData(res.data);
        })
    }

    useEffect(()=>{
        fetch();
    }, [])

    return (
        <div>
            <div className="modal_block block">
                    {
                        data && 
                        <Formik
                        initialValues={{ 
                            address: data.address , 
                            email: data.email, 
                            phone: data.phone, 
                            support_email: data.support_email, 
                            facebook: data.facebook ? data.facebook : '',
                            telegram: data.telegram ? data.telegram : '',
                            whatsapp: data.whatsapp ? data.whatsapp : '',
                            delivery_step_1: data.delivery_step_1 ? data.delivery_step_1 : '',
                            delivery_step_2: data.delivery_step_2 ? data.delivery_step_2 : '',
                            delivery_step_3: data.delivery_step_3 ? data.delivery_step_3 : '',
                        }}
                        onSubmit={values => {
                            values.lang = lang;
                            axios.post(`${config.api}site/update-footer`, values, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            });
                        }}
                        validationSchema={Yup.object().shape({
                            address: Yup.string().required('Обязательно'),
                            email: Yup.string().required('Обязательно'),
                            phone: Yup.string().required('Обязательно'),
                            support_email: Yup.string().required('Обязательно'),
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-6">

                                            <div className="form_group">
                                                <label>Адрес * </label>
                                                <input
                                                    type="text"
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="address"
                                                    placeholder=""
                                                    className={
                                                        errors.address && touched.address
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.address && touched.address && (
                                                    <div className="form_error">{errors.address}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>E-mail * </label>
                                                <input
                                                    type="text"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email"
                                                    placeholder=""
                                                    className={
                                                        errors.email && touched.email
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="form_error">{errors.email}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Телефон * </label>
                                                <input
                                                    type="text"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone"
                                                    placeholder=""
                                                    className={
                                                        errors.phone && touched.phone
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="form_error">{errors.phone}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Почта для приема заявок с сайта * </label>
                                                <input
                                                    type="text"
                                                    value={values.support_email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="support_email"
                                                    placeholder=""
                                                    className={
                                                        errors.support_email && touched.support_email
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.support_email && touched.support_email && (
                                                    <div className="form_error">{errors.support_email}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            
                                            <div className="form_group">
                                                <label>Facebook</label>
                                                <input
                                                    type="text"
                                                    value={values.facebook}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="facebook"
                                                    placeholder=""
                                                    className={
                                                        errors.facebook && touched.facebook
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                /> 
                                            </div>

                                            <div className="form_group">
                                                <label>Telegram</label>
                                                <input
                                                    type="text"
                                                    value={values.telegram}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="telegram"
                                                    placeholder=""
                                                    className={
                                                        errors.telegram && touched.telegram
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                /> 
                                            </div>

                                            <div className="form_group">
                                                <label>Whatsapp</label>
                                                <input
                                                    type="text"
                                                    value={values.whatsapp}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="whatsapp"
                                                    placeholder=""
                                                    className={
                                                        errors.whatsapp && touched.whatsapp
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                /> 
                                            </div>

                                            <div className="form_group">
                                                <label>Информация о доставке 1 шаг</label>
                                                <input
                                                    type="text"
                                                    value={values.delivery_step_1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="delivery_step_1"
                                                    placeholder=""
                                                    className={
                                                        errors.delivery_step_1 && touched.delivery_step_1
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                /> 
                                            </div>

                                            <div className="form_group">
                                                <label>Информация о доставке 2 шаг</label>
                                                <input
                                                    type="text"
                                                    value={values.delivery_step_2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="delivery_step_2"
                                                    placeholder=""
                                                    className={
                                                        errors.delivery_step_2 && touched.delivery_step_2
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                /> 
                                            </div>

                                            <div className="form_group">
                                                <label>Информация о доставке 3 шаг</label>
                                                <input
                                                    type="text"
                                                    value={values.delivery_step_3}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="delivery_step_3"
                                                    placeholder=""
                                                    className={
                                                        errors.delivery_step_3 && touched.delivery_step_3
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                /> 
                                            </div>

                                        </div>


                                    </div>
                                    <div className="form_group">
                                        <button className="save_button">Сохранить</button>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
                     
                    }
                    <ToastContainer></ToastContainer>
            </div>
        </div>
      
    )
}

const Contacts = ({})=>{
    return (
        <div className="content_body">
            <div className="content_top">
                <div className="row">
                    <div className="col-9">
                        <h1>Страница "Контакты"</h1>
                     </div>
                 </div>
            </div>
            <div className="admin-wrapper">
                <h3>Українська версія</h3>
                <Edit lang="uk"></Edit>
                <h3>Русская версия</h3>
                <Edit lang="ru"></Edit>
                <h3>English version</h3>
                <Edit lang="en"></Edit>
            </div>
        </div>
    )
}

export default Contacts;
