import React,{ useEffect} from 'react';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import AddPhone from './addPhone';
import EditPhone from './editPhone';
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";

const Phones = ({ _id }) => {

    const [data, setData] = React.useState(null);
    const [edit, setEdit] = React.useState(null);
    const [add, setAdd] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}managers/phones-list?id=${_id}`, headers)
        .then(res => {
            setData(res.data);
        }).catch((err => {
            console.log(err);
        }));
    }

    useEffect(()=>{
        fetch();
    }, []);

    const onEdit =(e)=>{
        if(!edit){
            let phone = data.filter((s)=>{ return s._id === e.target.value })[0];
            setEdit(phone);
        }else{
            setEdit(null);
        }
    }

    const onCloseEdit =()=>{
        setEdit(null);
        fetch();
    }

    const onAdd =(e)=>{
        setAdd(!add)
    }

    const onCloseAdd =()=>{
        setAdd(null);
        fetch();
    }

    const onDelete = (e)=>{
        setEdit(null);
        setAdd(null);
        axios.post(`${config.api}managers/delete-phone`, { _id: e.target.value }, headers)
        .then(res => {
            fetch();
        }).catch((err => {
            fetch();
        }));
    }

    let phones = data ? data.map((phone, i)=>{
        return(
            <li className="list_item" key={i}>
                <div className="row justify-content-between">
                    <div className="col-4 d-flex align-items-center"><div>{phone.phone}</div></div>
                    <div className="col-4 d-flex align-items-center"><div>{phone.name}</div></div>
                    <div className="col-4 edit-btns-box">
                        <div className="button_clickable">
                             <button value={phone._id} onClick={onEdit}></button>
                             <EditIcon></EditIcon>
                        </div>
                        <div className="button_clickable">
                           <button value={phone._id} onClick={onDelete}> </button>
                           <DeleteIcon></DeleteIcon>
                        </div>
                    </div>
                </div>
            </li>
        )
    }) : [];

    return (
        <div className="block">
            <h2>Телефон ответственного по заказам</h2>
            <div className="row">
                <div className="col-6">
                    <ul className="list">
                        {
                            phones
                        }
                    </ul>
                </div>
            </div>
            {
                <button className="btn btn_black" onClick={onAdd}>{!add ? 'Добавить' : 'Убрать'}</button>
            }
            {
                edit &&
                <EditPhone data={edit} onCloseEdit={onCloseEdit}/>
            }
            {
                add && 
                <AddPhone _id={_id} onCloseAdd={onCloseAdd}/>
            }
        </div>
    )
}

export default Phones;
