import React,{ useState } from 'react';
import { Formik } from "formik";
import Translator from '../translator/translator';
import * as Yup from "yup";
import config from '../../config';
import axios from "axios";
import { ReCaptcha } from 'react-recaptcha-google'
import './register.css';

const translator = new Translator();

class CaptchaComponent extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
      this.verifyCallback = this.verifyCallback.bind(this);
    }
    componentDidMount() {
      if (this.captchaDemo) {

      }
    }
    onLoadRecaptcha() {
        if (this.captchaDemo) {
        }
    }
    verifyCallback(recaptchaToken) {
        this.props.setVerifyed();
      console.log(recaptchaToken, "<= your recaptcha token")
    }
    render() {
      return (
        <div>
          <ReCaptcha
              ref={(el) => {this.captchaDemo = el;}}
              size="normal"
              render="explicit"
              sitekey="6LffvpUbAAAAAER2IIB0mK-MQ6-AtaYGaePnhxll"
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.verifyCallback}
          />
        </div>
      );
    };
  };

const equalTo = (ref, msg) => {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const Register = ({  }) => {

    const [errorMessage, setErrorMessage] = useState(null);
    const [robotMessage, setRobotMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [notRobot, setNotRobot] = useState(true);

    const setVerifyed = ()=>{
        setNotRobot(true);
    }

    return (
        <div className="register_block">
            <h1>Регистрация</h1>
            {
                successMessage &&
                <h2>{successMessage}</h2>
            }
            <div className="modal_block">

                  <Formik
                        initialValues={{ firstname: "", phone: "", email: "", password: "", confirmPassword: "" }}
                        onSubmit={values => {

                            if(!notRobot){
                                setRobotMessage("Required");
                                return;
                            }

                            values.email2 = values.email;
                            values.file_save_days = 30;
                            values.allow_iframe = true; 
                            values.bonus = 0;
                            values.role = 'manager';
                            values.lastname = '-';
                            values.delivery_address = '-';
                            axios.post(`${config.api}managers/create`, values)
                            .then(res => {
                                setSuccessMessage("Ждите звонка от администратора")
                            }).catch((err => {
                                if((err.response) && (err.response.data == 'user with same email exist')){
                                    setErrorMessage(translator.t('user_email_exists'));
                                }else{
                                    setErrorMessage("Error");
                                }
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email().required('required'),
                            firstname: Yup.string().required('required'),
                            phone: Yup.string().required('required'),
                            password: Yup.string().required("Required"),
                            confirmPassword: Yup.string().equalTo(Yup.ref('password'), 'Passwords must match').required('Required')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-12">
                                            <div className="form_group">
                                                <label>Имя* </label>
                                                <input
                                                    type="text"
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="firstname"
                                                    placeholder="Іван"
                                                    className={
                                                        errors.firstname && touched.firstname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.firstname && touched.firstname && (
                                                    <div className="form_error">{errors.firstname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Номер телефона владельца аккаунта* </label>
                                                <input
                                                    type="text"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone"
                                                    placeholder="+8888888888"
                                                    className={
                                                        errors.phone && touched.phone
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="form_error">{errors.phone}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Название компании</label>
                                                <input
                                                    type="text"
                                                    value={values.company_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="company_name"
                                                    placeholder=""
                                                    className={
                                                        errors.company_name && touched.company_name
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.company_name && touched.company_name && (
                                                    <div className="form_error">{errors.company_name}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Почта* </label>
                                                <input
                                                    type="text"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email"
                                                    placeholder="john@gmail.com"
                                                    className={
                                                        errors.email && touched.email
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="form_error">{errors.email}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label className="b-form__label">Пароль</label>
                                                <input
                                                    type="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="password"
                                                    minlength="8"
                                                    className={
                                                        errors.password && touched.password
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.password && touched.password && (
                                                    <div className="form_error">{errors.password}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label className="b-form__label">Повторить пароль</label>
                                                <input
                                                    type="password"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="confirmPassword"
                                                    minlength="8"
                                                    className={
                                                        errors.confirmPassword && touched.confirmPassword
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.confirmPassword && touched.confirmPassword && (
                                                    <div className="form_error">{errors.confirmPassword}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                {/* <CaptchaComponent setVerifyed={setVerifyed}></CaptchaComponent>
                                                {
                                                robotMessage &&
                                                <div className="form_group">
                                                   <div className="form_error">{robotMessage}</div>
                                                </div>
                                               } */}
                                            </div>
                                            <div className="form_group">
                                                <button className="save_button">Сохранить</button>
                                            </div>
                                            {
                                                errorMessage &&
                                                <div className="form_group">
                                                   <div className="form_error">{errorMessage}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-6">
                                            {/* <div className="form_group">
                                                <label>Фамилия* </label>
                                                <input
                                                    type="text"
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="lastname"
                                                    placeholder="Snow"
                                                    className={
                                                        errors.lastname && touched.lastname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.lastname && touched.lastname && (
                                                    <div className="form_error">{errors.lastname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Почта владельца аккаунта* </label>
                                                <input
                                                    type="text"
                                                    value={values.email2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email2"
                                                    placeholder="john@gmail.com"
                                                    className={
                                                        errors.email2 && touched.email2
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email2 && touched.email2 && (
                                                    <div className="form_error">{errors.email2}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Адрес доставки готовой продукции</label>
                                                <input
                                                    type="text"
                                                    value={values.delivery_address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="delivery_address"
                                                    placeholder="N.Y"
                                                    className={
                                                        errors.delivery_address && touched.delivery_address
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.delivery_address && touched.delivery_address && (
                                                    <div className="form_error">{errors.delivery_address}</div>
                                                )}
                                            </div> */}
                                        </div>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
            </div>
        </div>
      
    )
}

export default Register;
