import React, { createRef } from 'react';
import DatePicker from "react-datepicker";
import { ReactComponent as CalendartIcon } from '../../assets/calendar.svg';
import "react-datepicker/dist/react-datepicker.css";

export default class DatePicker2 extends React.Component {
  constructor(props) {
    super(props);
    this.input = createRef();
    this.clickCalendar = this.clickCalendar.bind(this);
  }
  clickCalendar(){
      this.input.current.input.focus();
  }
  render() {
    return (
        <div className="date_group">
            <div className="date_right" onClick={this.clickCalendar}>
                <CalendartIcon></CalendartIcon>
            </div>
            <div className="date_left">
              <DatePicker
                ref={this.input}
                selected={this.props.selectedDays}
                onChange={(e)=>{  this.props.onDayChange(new Date(e))}}
                showYearDropdown
                dropdownMode="select"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                placeholderText="2021-07-12"
                />
            </div>
        </div>
    );
  }
}