import React from 'react';
import axios from "axios";
import config from '../../config';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import Edit from './edit';
import Orders from './orders';
import Popup from 'reactjs-popup';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import formatDate from "../../date";
import headers from "../../auth/headers";
import './clients.scss';
import {ReactComponent as CloseIcon} from "../../assets/close.svg";

export default class Clients extends React.Component{
    constructor(props){
        super(props);
        this.state = { data: [] , edit: null , history: null, searchText: '', tableHeight: ''};
        this.fetch = this.fetch.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onOpenEdit = this.onOpenEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.onOpenHistory = this.onOpenHistory.bind(this);
        this.closeHistory = this.closeHistory.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
    }
    onSearchClick(){
      this.fetch();
    }
    onSearchTextChange(e){
      this.setState({ searchText: e.target.value });
    }
    onOpenHistory(e){
      this.setState({ history: e.target.value });
    }
    closeHistory(){
      this.setState({ history: null });
    }
    componentDidMount(){
      this.fetch();

      //Set table height
      if(window.innerWidth < 1900) {
        this.setState({ tableHeight: 'calc(100vh - 350px)' });
      } else {
        this.setState({ tableHeight: 'calc(100vh - 430px)' });
      }
    }
    onOpenEdit(e){
        let edit = this.state.data.filter((c)=>{ return c._id === e.target.value })[0];
        this.setState({ edit: edit });
    }
    closeEdit(){
      this.setState({ edit: null });
    }
    onDeleteClick(e){
        const _this = this;
        axios.post(`${config.api}clients/delete`, { _id: e.target.value }, headers).then((res) => {
             _this.fetch();
         }
        ).catch((err => {
            console.log(err);
        }));
    }
    fetch(){
        const _this = this;
        axios.get(`${config.api}clients/list?text=${this.state.searchText}`, headers).then((res) => {
            _this.setState({ data: res.data});
         }
        ).catch((err => {
            console.log(err);       
        }));
    }

    render(){
          const columns = [
            {
              name: 'ID менеджера',
              selector: 'manager_no',
              sortable: true,
              width: '140px',
              center: true,
              cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.manager_no}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Юридическое лицо менеджера',
                selector: 'legal_entity',
                sortable: true,
                width: '200px',
                center: true,
              },
            {
                name: 'ID клиента',
                selector: 'no',
                sortable: true,
                width: '100px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.no}</p>
                        }
                        </div>
                    )
                },
              },
            {
              name: 'Дата регистрации',
              selector: 'date',
              sortable: true,
                width: '140px',
              center: true,
              cell: row =>{
                return(
                    <div data-tag="allowRowEvents">{
                        formatDate(row.date)
                    }</div>
                )
               }
            },
            {
                name: 'Имя клиента',
                selector: 'firstname',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Фамилия клиента',
                selector: 'lastname',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Номер телефона',
                selector: 'phone',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
              name: 'Компания клиента',
              selector: 'company',
              sortable: true,
                width: '140px',
              center: true,
            },
            {
                name: 'Телефон клиента',
                selector: 'phone',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Почта клиента',
                selector: 'email',
                sortable: true,
                width: '220px',
                center: true,
            },
            {
              name: 'История заказов',
              selector: 'history',
              sortable: true,
              width: '200px',
              center: true,
              cell: row =>{
                return(
                    <div className="grid_control_wrapper">
                       <div className="button_clickable_edit">
                          <button className="grid_delete" onClick={this.onOpenHistory} value={row._id}>Посмотреть</button>
                        </div>
                    </div>
                )
              },
            },
            {
              name: 'Коментарии',
              selector: 'comment',
              sortable: true,
                width: '250px',
                center: 'true',
                wrap: 'true',
            }
            // ,
            // {
            //     name: '',
            //     width: '200px',
            //     selector: 'edit',
            //     cell: row =>{
            //         return(
            //             <div className="grid_control_wrapper">
            //                <div className="button_clickable_edit">
            //                   <button className="grid_delete" onClick={this.onOpenEdit} value={row._id}> Редактировать</button>
            //                   <PenIcon></PenIcon>
            //                 </div>
            //             </div>
            //         )
            //     },
            // },
            // {
            //     name: '',
            //     selector: 'delete',
            //     center: true,
            //     cell: row =>{
            //         return(
            //             <div className="grid_control_wrapper">
            //               <div className="button_clickable">
            //                 <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}>
            //                     <DeleteIcon></DeleteIcon>
            //                 </button>
            //               </div>
            //             </div>
            //         )
            //     },
            // }
          ];

          const customStyles = {
            header: {
                style: {
                  minHeight: '56px',
                },
              },
              headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: defaultThemes.default.divider.default,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: '1px',
                  borderLeftColor: defaultThemes.default.divider.default,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                },
              },
              headCells: {
                style: {
                  '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                  },
                },
              },
              rows: {
                style: {
                    minHeight: '110px',
                }
              },
              cells: {
                style: {
                  '&': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                    paddingLeft: '8px', 
                    paddingRight: '8px',
                    paddingTop: '28px',
                    paddingBottom: '28px',
                  }
                },
              },
          };

          createTheme('solarized', {
            context: {
              background: '#cb4b16',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });

        const paginationComponentOptions = {
            rowsPerPageText: 'Строк на странице',
            rangeSeparatorText: 'из',
        };
        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-6">
                            <h1>Клиенты менеджеров</h1>
                        </div>
                        <div className="col-6 content_top__search-box search_box">
                            <div className="search_text">
                                <input type="text" onChange={this.onSearchTextChange} value={this.state.searchText} placeholder="Поиск" />
                                <button className="search_btn" onClick={this.onSearchClick}>
                                    <SearchIcon></SearchIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <PerfectScrollbar>
                       <DataTable
                            sortable={true}
                            noHeader={true}
                            columns={columns}
                            theme="solarized"
                            data={this.state.data}
                            customStyles={customStyles}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={this.state.tableHeight}
                            responsive
                        />
                    </PerfectScrollbar>
                </div>
                <Popup open={this.state.edit !== null} onClose={this.closeEdit}>
                   <Edit data={this.state.edit} closeEdit={this.closeEdit}></Edit>
                </Popup>
                <Popup open={this.state.history !== null} onClose={this.closeHistory} className='history_popup'>
                    <button className="close" onClick={this.closeHistory}>
                        <CloseIcon></CloseIcon>
                    </button>
                    <PerfectScrollbar>
                        <Orders id={this.state.history} closeEdit={this.closeHistory}></Orders>
                    </PerfectScrollbar>
                </Popup>
            </div>
        )
    }
}