
import React from 'react';
import axios from "axios";
import config from '../../config';
import Popup from 'reactjs-popup';
import AddTemplate from './addTemplate';
import EditTemplate from './editTemplate';
import headers from "../../auth/headers";
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './template.css';

export default class Templates extends React.Component{
    constructor(){
       super();
       this.state = { list: [], add: false, edit: null};
       this.fetch = this.fetch.bind(this);
       this.onAddClick = this.onAddClick.bind(this);
       this.onAddClose = this.onAddClose.bind(this);
       this.onDelete = this.onDelete.bind(this);
       this.onEditClick = this.onEditClick.bind(this);
       this.onCloseEdit = this.onCloseEdit.bind(this);
    }
    onEditClick(e){
        let template = this.state.list.filter((t)=>{ return t._id === e.target.value })[0];
        this.setState({ edit: template });
    }
    onCloseEdit(){
        this.setState({ edit: null });
        this.fetch();
    }
    onDelete(e){
        let _this = this;
        axios.post(`${config.api}products/delete-product-template`, { _id: e.target.value }, headers ).then(response => {
            _this.fetch();
        });
    }
    onAddClick(){
       this.setState({ add: true });
    }
    onAddClose(){
        this.setState({ add: false });
        this.fetch();
    }
    componentDidMount(){
       this.fetch();
    }
    fetch(){
        let _this = this;
        let product_id = this.props.match.params.id;
        axios.get(`${config.api}products/template?id=${product_id}`, headers).then(response => {
            let data = response.data;
            _this.setState({ list: data });
        });
    }
    render(){
        let templates = this.state.list.map((template)=>{
            return(
                <li key={template._id}>
                    <div className="row">
                        <div className="col-2">
                            <p>Фон</p>
                            <img src={config.api + template.editor_background}></img>
                        </div>
                        <div className="col-2">
                            <p>Длина области</p>
                            <p>{template.clip_width}</p>
                        </div>
                        <div className="col-2">
                            <p>Высота области</p>
                            <p>{template.clip_height}</p>
                        </div>
                        <div className="col-2">
                            <p>Отступ X</p>
                            <p>{template.clip_offset_x}</p>
                        </div>
                        <div className="col-2">
                            <p>Отступ Y</p>
                            <p>{template.clip_offset_y}</p>
                        </div>
                        <div className="col-1">
                            <button className="button_clickable" onClick={this.onEditClick} value={template._id}><PenIcon></PenIcon></button>
                        </div>
                        <div className="col-1">
                            <button className="button_clickable" onClick={this.onDelete} value={template._id}><DeleteIcon></DeleteIcon></button>
                        </div>
                    </div>
                </li>
            )
        })
        return(
            <div className="content_body">
                <h1>Отображение в конструкторе</h1>
                <div className="block">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn_black" onClick={this.onAddClick}>Добавить</button>
                        </div>
                    </div>
                    <ul className="template_list">
                        {
                            templates
                        }
                    </ul>
                </div>
                <Popup modal={true}  position="top left" open={this.state.add} onClose={this.onAddClose}>
                    <div className="edit_content">
                        <button className="close" onClick={this.onAddClose}>
                            <CloseIcon></CloseIcon>
                        </button>
                        <PerfectScrollbar>
                            <AddTemplate product_id={this.props.match.params.id} onAddClose={this.onAddClose}></AddTemplate>
                        </PerfectScrollbar>
                    </div>
                </Popup>
                <Popup modal={true}  position="top left" open={this.state.edit != null} onClose={this.onCloseEdit}>
                    <div className="edit_content">
                        <button className="close" onClick={this.onCloseEdit}>
                            <CloseIcon></CloseIcon>
                        </button>
                        <PerfectScrollbar>
                            <EditTemplate data={this.state.edit} onCloseEdit={this.onCloseEdit}></EditTemplate>
                        </PerfectScrollbar>
                    </div>
                </Popup>
            </div>
        )
    }
}
