import React from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";
import '../manager.css';

const Add = () => {

    const [images, setImages] = React.useState([]);
    const onChange = (imageList) => {
      setImages(imageList);
    };

    return (
        <div className="block">
            <h1>Добавить менеджера</h1>
            <div className="modal_block">
                    <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={1}
                        dataURLKey="data_url"
                    >
                        {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps
                        }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            {
                                imageList.length < 1 &&
                                <button
                                    style={isDragging ? { color: "red" } : null}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    Click or Drop here
                                </button>
                            }
                            {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image.data_url} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                <button onClick={() => onImageUpdate(index)}>Update</button>
                                <button onClick={() => onImageRemove(index)}>Remove</button>
                                </div>
                            </div>
                            ))}
                        </div>
                        )}
                    </ImageUploading>
                  <Formik
                        initialValues={{ firstname: "", phone: "", email: "", lastname: "", delivery_address: "", email2: "", file_save_days: 30,  allow_iframe: true,  bonus: 0 }}
                        onSubmit={values => {

                            values.role = 'manager';
                            values.password = '12345';

                            if(images.length){
                                let base64 = images[0]['data_url'];
                                values.logo = base64;
                            }

                            axios.post(`${config.api}managers/create`, values, headers)
                            .then(res => {
                                  
                            }).catch((err => {
                            
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            email2: Yup.string().email().required('required'),
                            email: Yup.string().email().required('required'),
                            firstname: Yup.string().required('required'),
                            phone: Yup.string().required('required'),
                            lastname: Yup.string().required('required'),
                            delivery_address: Yup.string().required('required'),
                            file_save_days: Yup.number().required('required'),
                            bonus: Yup.number().required('required')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Имя* </label>
                                                <input
                                                    type="text"
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="firstname"
                                                    placeholder="Іван"
                                                    className={
                                                        errors.firstname && touched.firstname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.firstname && touched.firstname && (
                                                    <div className="form_error">{errors.firstname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Номер телефона владельца аккаунта* </label>
                                                <input
                                                    type="text"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone"
                                                    placeholder="+8888888888"
                                                    className={
                                                        errors.phone && touched.phone
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="form_error">{errors.phone}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Почта регистрации* </label>
                                                <input
                                                    type="text"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email"
                                                    placeholder="john@gmail.com"
                                                    className={
                                                        errors.email && touched.email
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="form_error">{errors.email}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Фамилия* </label>
                                                <input
                                                    type="text"
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="lastname"
                                                    placeholder="Snow"
                                                    className={
                                                        errors.lastname && touched.lastname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.lastname && touched.lastname && (
                                                    <div className="form_error">{errors.lastname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Почта владельца аккаунта* </label>
                                                <input
                                                    type="text"
                                                    value={values.email2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email2"
                                                    placeholder="john@gmail.com"
                                                    className={
                                                        errors.email2 && touched.email2
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email2 && touched.email2 && (
                                                    <div className="form_error">{errors.email2}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Адрес доставки готовой продукции</label>
                                                <input
                                                    type="text"
                                                    value={values.delivery_address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="delivery_address"
                                                    placeholder="N.Y"
                                                    className={
                                                        errors.delivery_address && touched.delivery_address
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.delivery_address && touched.delivery_address && (
                                                    <div className="form_error">{errors.delivery_address}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Срок хранения файлов (дней)</label>
                                                <input
                                                    type="number"
                                                    value={values.file_save_days}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="file_save_days"
                                                    placeholder="0"
                                                    min="0"
                                                    className={
                                                        errors.file_save_days && touched.file_save_days
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.file_save_days && touched.file_save_days && (
                                                    <div className="form_error">{errors.file_save_days}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Бонус менеджера</label>
                                                <input
                                                    type="number"
                                                    value={values.bonus}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="bonus"
                                                    placeholder="0"
                                                    min="0"
                                                    className={
                                                        errors.bonus && touched.bonus
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.bonus && touched.bonus && (
                                                    <div className="form_error">{errors.bonus}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Разрешить создавать iFrame</label>
                                                <Field type="checkbox" name="allow_iframe" />
                                            </div>
                                            <div className="form_group">
                                                <button className="save_button">Сохранить</button>
                                            </div>
                                        </div>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
            </div>
        </div>
      
    )
}

export default Add;
