import React ,{useEffect} from 'react';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import AddLegal from './addLegal';
import EditLegal from './editLegal';
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";

const Legals = ({ _id }) => {

    const [data, setData] = React.useState(null);
    const [edit, setEdit] = React.useState(null);
    const [add, setAdd] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}managers/legal-entities-list?id=${_id}`, headers)
        .then(res => {
            setData(res.data);
        }).catch((err => {
            console.log(err);
        }));
    }

    useEffect(()=>{
        fetch();
    }, []);

    const onEdit =(e)=>{
        let entity = data.filter((en)=>{ return en._id === e.target.value })[0];
        setEdit(entity)
    }

    const onCloseEdit =()=>{
        setEdit(null);
        fetch();
    }

    const onAdd =(e)=>{
        setAdd(!add)
    }

    const onCloseAdd =()=>{
        setAdd(null);
        fetch();
    }

    const onDelete = (e)=>{
        setAdd(null);
        setEdit(null);
        axios.post(`${config.api}managers/delete-legal-entity`, { _id: e.target.value }, headers)
        .then(res => {
            fetch();
        }).catch((err => {
            fetch();
        }));
    }

    let enteties = data ? data.map((entety, i)=>{
        return(
            <li className="list_item" key={i}>
                <div className="row justify-content-between">
                    <div className="col-1 d-flex align-items-center">{i+1}</div>
                    <div className="col-3 d-flex align-items-center">{entety.name}</div>
                    <div className="col-2 d-flex align-items-center">{entety.inn}</div>
                    <div className="col-3 d-flex align-items-center">{entety.address}</div>
                    <div className="col-3 edit-btns-box">
                        <div className="button_clickable">
                           <button value={entety._id} onClick={onEdit}></button>
                           <EditIcon></EditIcon>
                        </div>
                        <div className="button_clickable">
                            <button value={entety._id} onClick={onDelete}></button>
                            <DeleteIcon></DeleteIcon>
                        </div>
                    </div>
                </div>
            </li>
        )
    }) : [];

    return (
        <div className="block">
            <h2>Юридическое лицо</h2>
            <div className="row">
                <div className="col-9">
                    <ul className="list">
                        {
                            enteties
                        }
                    </ul>
                </div>
            </div>
            {
                <button className="btn btn_black" onClick={onAdd}>{!add ? 'Добавить' : 'Убрать'}</button>
            }
            {
                edit &&
                <EditLegal data={edit} onCloseEdit={onCloseEdit}></EditLegal>
            }
            {
                add && 
                <AddLegal _id={_id} onCloseAdd={onCloseAdd}/>
            }
        </div>
    )
}

export default Legals;
