
import React from 'react';
import axios from "axios";
import config from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import headers from "../../auth/headers";
import PerfectScrollbar from 'react-perfect-scrollbar';
import './template.css';
// import Translator from "../translator/translator"

// const translator = new Translator();

export default class Template extends React.Component{
    constructor(){
       super();
       this.state = {
        name_ru: "Зона печати",  
        name_uk: "Зона друку",
        name_en: "Print zone", 
        clip_width: 0, clip_height: 0, clip_offset_x: 0, clip_offset_y: 0, editor_background: null, glb: null, errorMessage: null, real_width: 0, real_height: 0, fill: false, full_print: false, print_price: 0};
       this.onUploadBackground = this.onUploadBackground.bind(this);
       this.onUploadGlb = this.onUploadGlb.bind(this);
       this.onSave = this.onSave.bind(this);
       this.onWidthChange = this.onWidthChange.bind(this);
       this.onHeightChange = this.onHeightChange.bind(this);
       this.onXChange = this.onXChange.bind(this);
       this.onYChange = this.onYChange.bind(this);
       this.onRemoveBackground = this.onRemoveBackground.bind(this);
       this.onRemoveModel = this.onRemoveModel.bind(this);
       this.onRealWidthChange = this.onRealWidthChange.bind(this);
       this.onRealHeightChange = this.onRealHeightChange.bind(this);
       this.onFillChange = this.onFillChange.bind(this);
       this.onFullPrint = this.onFullPrint.bind(this);
       this.onPrintPriceChange = this.onPrintPriceChange.bind(this);
       this.onNameChange = this.onNameChange.bind(this);
       this.onNameChangeUA = this.onNameChangeUA.bind(this);
       this.onNameChangeEN = this.onNameChangeEN.bind(this);
    }
    onNameChange(e){
        this.setState({ name_ru: e.target.value });
    }
    onNameChangeUA(e){
        this.setState({ name_uk: e.target.value });
    }
    onNameChangeEN(e){
        this.setState({ name_en: e.target.value });
    }
    onPrintPriceChange(e){
        this.setState({ print_price: e.target.value });
    }
    onFillChange(e){
        this.setState({ fill: e.target.checked  });
    }
    onFullPrint(e){
        this.setState({ full_print: e.target.checked  });
    }
    onRealWidthChange(e){
        this.setState({ real_width: e.target.value });
    }
    onRealHeightChange(e){
        this.setState({ real_height: e.target.value });
    }
    onRemoveModel(){
        this.setState({ model: null });
    }
    onRemoveBackground(){
        this.setState({ editor_background: null });
    }
    componentDidMount(){

    }
    onWidthChange(e){
        this.setState({ clip_width: e.target.value });
    }
    onHeightChange(e){
        this.setState({ clip_height: e.target.value });
    }
    onXChange(e){
        this.setState({ clip_offset_x: e.target.value });
    }
    onYChange(e){
        this.setState({ clip_offset_y: e.target.value });
    }
    onUploadGlb(e){
        const _this = this;
        let file = e.target.files[0];
        e.target.value = '';
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            let model = reader.result;
            _this.setState({ model: model });
        };
    }

    onUploadBackground(e){
        const _this = this;
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let base64 = reader.result;
            _this.setState({ editor_background: base64 });
        };
    }

    onSave(e){
        e.preventDefault();
        const that = this;
        let data = this.state;
        data.product_id = this.props.product_id;

        if(!this.state.name_uk || this.state.name_uk.length === 0){
            toast.error("Имя обязательно", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.name_ru || this.state.name_ru.length === 0){
            toast.error("Имя обязательно", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.name_en || this.state.name_en.length === 0){
            toast.error("Имя обязательно", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.clip_width){
            toast.error("Ширина обязательна", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.clip_height){
            toast.error("Высота обязательна", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.clip_offset_x){
            toast.error("X обязательно", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.clip_offset_y){
            toast.error("Y обязательно", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.print_price){
            toast.error("Стоимость запечатки обязательна", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }


        if(!this.state.editor_background){
            toast.error("Фон обязательный", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if(!this.state.model){
            toast.error("Model обязательна", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        axios.post(`${config.api}products/product-create-template`, this.state , headers).then(response => {
            that.props.onAddClose();
        });
    }
    render(){
        return(
            <div className="popup">
                <h2 className="popup__title">Добавить шаблон</h2>
                <div className="popup__inner">
                    <div className="block">
                         <div className="row">
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Название зоны запечатки RU</label>
                                    <input type="text" value={this.state.name_ru} onChange={this.onNameChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Название зоны запечатки UA</label>
                                    <input type="text" value={this.state.name_uk} onChange={this.onNameChangeUA}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Название зоны запечатки EN</label>
                                    <input type="text" value={this.state.name_en} onChange={this.onNameChangeEN}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Реальна ширина (mm)</label>
                                    <input type="number" value={this.state.real_width} onChange={this.onRealWidthChange}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Реальная высота (mm)</label>
                                    <input type="number" value={this.state.real_height} onChange={this.onRealHeightChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="row">
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Ширина</label>
                                    <input type="number" value={this.state.clip_width} onChange={this.onWidthChange}/>
                                </div>
                                <div className="form_group">
                                    <label>X</label>
                                    <input type="number" value={this.state.clip_offset_x} onChange={this.onXChange}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form_group">
                                    <label>Высота</label>
                                    <input type="number" value={this.state.clip_height} onChange={this.onHeightChange}/>
                                </div>
                                <div className="form_group">
                                    <label>Y</label>
                                    <input type="number" value={this.state.clip_offset_y} onChange={this.onYChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="row">
                            <div className="col-6">
                                    <div className="form_group">
                                        <label>Фон</label>
                                        {
                                            this.state.editor_background &&
                                            <div className="template_img">
                                                <img src={this.state.editor_background.indexOf('base64') < 0 ? `${config.api}${this.state.editor_background}` : this.state.editor_background }></img>
                                                <button className="btn btn_black" onClick={this.onRemoveBackground}>Удалить</button>
                                            </div>
                                        }
                                        {
                                            !this.state.editor_background && 
                                            <input 
                                                type="file" 
                                                accept="image/png"
                                                onChange={this.onUploadBackground}/>
                                        }
                                    </div>
                            </div>
                            <div className="col-6">
                                    <div className="form_group">
                                        <label>Модель</label>
                                        {
                                            this.state.model &&
                                            <div className="template_img d-flex align-items-center">
                                                <h5>Model</h5>
                                                <button className="ml-2 btn btn_black" onClick={this.onRemoveModel}>Удалить</button>
                                            </div>
                                        }
                                        {
                                            !this.state.model && 
                                            <input 
                                            type="file" 
                                            accept=".glb"
                                            onChange={this.onUploadGlb}/>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row block">
                        <div className="col-6">
                                <div className="form_group d-flex align-items-center">
                                    <input type="checkbox" checked={this.state.full_print} onChange={this.onFullPrint} />
                                    <label className="pl-2 pb-0">Полная запечатка</label>
                                </div>
                                <div className="form_group">
                                    <label>Стоимость полной запечатки</label>
                                    <input type="number" value={this.state.print_price} onChange={this.onPrintPriceChange}/>
                                </div>
                        </div>
                        <div className="col-6">
                            <div className="form_group d-flex align-items-center">
                                <input type="checkbox" checked={this.state.fill} onChange={this.onFillChange} />
                                <label className="pl-2 pb-0">Заливка</label>
                            </div>
                        </div>
                    </div>
                    <div className="form_group">
                        <button className="save_button" onClick={this.onSave}>Сохранить</button>
                    </div>
                    <ToastContainer></ToastContainer>
                </div>
            </div>
        )
    }
}
