import React,{ useState, useEffect} from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import config from '../../../config';
import Legals from '../legals/legals';
import Sites from '../sites/sites';
import Phones from '../phones/phones';
import headers from "../../../auth/headers";
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import '../manager.css';

const Edit = ({ _id }) => {

    let languages = [
        { value: 'ru', label: 'Русский' },
        { value: 'en', label: 'English' },
        { value: 'uk', label: 'Український' },
    ]

    const [language, setLanguage] = useState(languages[0]);

    const onLanguageChange = (e)=>{
        setLanguage(e);
    }

    const [data, setData] = React.useState(null);
    const [changeLogo, setChangeLogo] = React.useState(false);
    const [showIframe, setShowIframe] = React.useState(false);

    const iFrameCode = `<iframe src="${config.editor}editor/${_id}/${language.value}"></iframe>`;

    const fetch = ()=>{
        axios.get(`${config.api}managers/get?id=${_id}`, headers)
        .then(res => {
            setData(res.data);
        }).catch((err => {

        }));
    }

    useEffect(()=>{
        fetch();
    }, [])


    const [images, setImages] = React.useState([]);
    const onChange = (imageList, addUpdateIndex) => {
        setChangeLogo(true);
        setImages(imageList);
    };

    const onShowIframe = ()=>{
        setShowIframe(!showIframe);
    }

    const onResetPassword = ()=>{
        axios.post(`${config.api}managers/reset-password`, { email: data.email2}, headers)
        .then(res => {
            toast.success("Новый пароль отправлен Вам на почту", {
                position: toast.POSITION.TOP_CENTER
            });
        }).catch((err => {
             console.log(err)
        }));
    }


    return (
        <div className="popup">
            <h2 className="popup__title">Редактировать менеджера</h2>
            <div className="popup__inner">
            {
                data &&
                <div>
                  <Formik
                        initialValues={{ firstname: data.firstname, phone: data.phone , email: data.email, lastname: data.lastname, delivery_address: data.delivery_address, email2: data.email2, file_save_days: data.file_save_days,  allow_iframe: data.allow_iframe,  bonus: data.bonus, aprooved: data.aprooved, hide_price: data.hide_price}}
                        onSubmit={values => {

                            values._id = data._id;

                            if(images.length && changeLogo){
                                let base64 = images[0]['data_url']
                                values.logo = base64;
                            }else{
                                values.logo = data.logo;
                            }

                            values.changeLogo = changeLogo;

                            axios.post(`${config.api}managers/update`, values, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }).catch((err => {
                                toast.error("Ошибка сохранения", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            email2: Yup.string().email().required('Обязательно'),
                            email: Yup.string().email().required('Обязательно'),
                            firstname: Yup.string().required('Обязательно'),
                            phone: Yup.string().required('Обязательно'),
                            lastname: Yup.string().required('Обязательно'),
                            delivery_address: Yup.string().required('Обязательно'),
                            file_save_days: Yup.number().required('Обязательно'),
                            bonus: Yup.number().required('Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form" id="manager_form">
                                     <div className="row">
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Имя* </label>
                                                <input
                                                    type="text"
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="firstname"
                                                    placeholder="Іван"
                                                    className={
                                                        errors.firstname && touched.firstname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.firstname && touched.firstname && (
                                                    <div className="form_error">{errors.firstname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Номер телефона владельца аккаунта * </label>
                                                <input
                                                    type="text"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone"
                                                    placeholder="+8888888888"
                                                    className={
                                                        errors.phone && touched.phone
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="form_error">{errors.phone}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Почта регистрации * </label>
                                                <input
                                                    type="text"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email"
                                                    placeholder="john@gmail.com"
                                                    className={
                                                        errors.email && touched.email
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="form_error">{errors.email}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Срок хранения файлов (дней)</label>
                                                <input
                                                    type="number"
                                                    value={values.file_save_days}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="file_save_days"
                                                    placeholder="0"
                                                    min="0"
                                                    className={
                                                        errors.file_save_days && touched.file_save_days
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.file_save_days && touched.file_save_days && (
                                                    <div className="form_error">{errors.file_save_days}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Бонус менеджера</label>
                                                <input
                                                    type="number"
                                                    value={values.bonus}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="bonus"
                                                    placeholder="0"
                                                    min="0"
                                                    className={
                                                        errors.bonus && touched.bonus
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.bonus && touched.bonus && (
                                                    <div className="form_error">{errors.bonus}</div>
                                                )}
                                            </div>
                                            <div className="form_group d-flex align-items-center">
                                                <Field type="checkbox" name="allow_iframe" />
                                                <label className="pl-2 pb-0">Разрешить создавать iFrame</label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Фамилия * </label>
                                                <input
                                                    type="text"
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="lastname"
                                                    placeholder="Snow"
                                                    className={
                                                        errors.lastname && touched.lastname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.lastname && touched.lastname && (
                                                    <div className="form_error">{errors.lastname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Почта владельца аккаунта * </label>
                                                <input
                                                    type="text"
                                                    value={values.email2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email2"
                                                    placeholder="john@gmail.com"
                                                    className={
                                                        errors.email2 && touched.email2
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.email2 && touched.email2 && (
                                                    <div className="form_error">{errors.email2}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Адрес доставки готовой продукции</label>
                                                <input
                                                    type="text"
                                                    value={values.delivery_address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="delivery_address"
                                                    placeholder="N.Y"
                                                    className={
                                                        errors.delivery_address && touched.delivery_address
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.delivery_address && touched.delivery_address && (
                                                    <div className="form_error">{errors.delivery_address}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Максимальный кредитный лимит</label>
                                                <input
                                                    type="number"
                                                    value={data.max_credit}
                                                    readOnly={true}
                                                    disabled={true}
                                                    name="max_credit"
                                                    placeholder="0"
                                                    min="0"
                                                />
                                            </div>
                                            <div className="form_group">
                                                <label>Баланс</label>
                                                <input
                                                    type="number"
                                                    value={data.balance}
                                                    readOnly={true}
                                                    disabled={true}
                                                    name="balance"
                                                    placeholder="0"
                                                    min="0"
                                                />
                                            </div>

                                            <div className="form_group d-flex align-items-center">
                                                <Field type="checkbox" name="aprooved" />
                                                <label className="pl-2 pb-0">Подтвержден</label>
                                            </div>
                                            <div className="form_group d-flex align-items-center">
                                                <Field type="checkbox" name="hide_price" />
                                                <label className="pl-2 pb-0">Отключать цены с конструктора</label>
                                            </div>
                                        </div>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
            </div>

            }
            <div className="block">
            {
                    (data != null) && (data.logo) && (!changeLogo) &&
                        <div className="row">
                            <div className="col-2">
                               <div className="form_group">
                                   <label>Лого</label>
                                   <div className="logo_wrapper">
                                       <img src={config.api + data.logo}></img>
                                   </div>
                               </div>
                               <div className="form_group">
                                  <button className="btn" onClick={()=>{ setChangeLogo(true)}}>Изменить</button>
                               </div>
                            </div>
                        </div>
                    }
                    {
                         (data != null) && (!data.logo || changeLogo) &&
                         <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={1}
                                dataURLKey="data_url"
                            >
                         {({
                             imageList,
                             onImageUpload,
                             onImageUpdate,
                             onImageRemove,
                             isDragging,
                             dragProps
                         }) => (

                         <div className="upload__image-wrapper">
                               <div className="row">
                                    <div className="col-3">
                                        <div className="form_group">
                                            {
                                                imageList.length < 1 &&
                                                <button
                                                    className="btn"
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Выбрать файл
                                                </button>
                                            }

                                            {
                                                imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <div className="logo_wrapper">
                                                        <img src={image.data_url} />
                                                        </div>
                                                        <div className="image-item__btn-wrapper">
                                                            <div className="form_group">
                                                            <button onClick={() => onImageUpdate(index)}  className="btn">Update</button>
                                                            </div>
                                                            <div className="form_group">
                                                            <button onClick={() => onImageRemove(index)}  className="btn">Remove</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                         </div>
                         )}
                     </ImageUploading>
                    }
            </div>
            {
                data &&
                <div className="block">
                    <div className="row">
                        <div className="col-3">
                        <div className="form_group">
                            <input type="text" value={data.email} disabled={true}></input>
                        </div>
                        </div>
                        <div className="col-3">
                        <div className="form_group">
                            <button onClick={onResetPassword} className="btn">Сбросить пароль</button>
                        </div>
                        </div>
                    </div>
                </div>
            }
            <div  className="block">
                <div className="row">
                    <div className="col-6">
                        <div className="iframe_block">
                        <button className="iframe_button" onClick={onShowIframe}>Создать постоянный код iFrame для сайта</button>
                        {
                            showIframe &&
                            <div className="iframe_code">
                                <textarea value={iFrameCode} rows={5} readOnly={true}></textarea>
                            </div>
                        }
                      </div>
                   </div>
                   <div className="col-2">
                       <Select value={language} options={languages} onChange={onLanguageChange}></Select>
                   </div>
                </div>
            </div>
            {
                data &&
                <Phones _id={data._id}></Phones>
            }
            {
                data &&
                <Sites _id={data._id}></Sites>
            }
            {
                data &&
                <Legals _id={data._id}></Legals>
            }
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            ></ToastContainer>

            <div className="form_group">
                <button form="manager_form" className="save_button">Сохранить</button>
            </div>
            </div>
        </div>
    )
}

export default Edit;
