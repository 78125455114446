import React from 'react';
import Switch from "react-switch";
import Checkbox  from "rc-checkbox";
import './sizes.css';

export default class Sizes extends React.Component{
    constructor(props){
        super(props);
        this.state = { hasSize: false, sizes: {} };
        this.onHasSize = this.onHasSize.bind(this);
        this.onSizeCheck = this.onSizeCheck.bind(this);
    }
    onHasSize(){
        if(this.state.hasSize){
            this.setState({ hasSize: false, sizes: {} });
            this.props.setSizes(null);
        }else{
            this.setState({ hasSize: true });
            this.props.setSizes([]);
        }
    }
    onSizeCheck(e){
        let sizes = this.state.sizes;
        let size = e.target.value;
        if(sizes.hasOwnProperty(size)){
             delete sizes[size];
        }else{
            sizes[size] = true;
        }
        this.setState({ sizes: sizes });
        this.props.setSizes(Object.keys(sizes));
    }
    componentDidMount(){
        if(this.props.sizes){
            let sizes = {};
            this.props.sizes.forEach(element => {
                sizes[element] = true;
            });
            this.setState({ hasSize: true, sizes: sizes });
        }else{
            this.setState({ hasSize: false });
        }
    }
    render(){
        return(
            <div className="sizes_block">
                <div className="form_group">
                    <label>Размеры</label>
                </div>
                <Switch 
                    onChange={this.onHasSize} 
                    checked={this.state.hasSize} 
                    uncheckedIcon={
                        <div className="grid-text grid-off">Выкл</div>
                    }
                    checkedIcon={
                        <div className="grid-text grid-off">Вкл</div>
                    }
                    height={35}
                    width={70}
                    handleDiameter={28}
                />
                {
                    this.state.hasSize &&
                    <div className="row">
                        <div className="col-6">
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('XS')} value="XS"> </Checkbox>
                                <label>XS</label>
                            </div>
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('S')} value="S"> </Checkbox>
                                <label>S</label>
                            </div>
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('M')} value="M"> </Checkbox>
                                <label>M</label>
                            </div>
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('L')} value="L"> </Checkbox>
                                <label>L</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('XL')} value="XL"> </Checkbox>
                                <label>XL</label>
                            </div>
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('XXL')} value="XXL"> </Checkbox>
                                <label>XXL</label>
                            </div>
                            <div className="size_row">
                                <Checkbox onChange={this.onSizeCheck} checked={this.state.sizes.hasOwnProperty('XXXL')} value="XXXL"> </Checkbox>
                                <label>XXXL</label>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}