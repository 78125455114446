import React from 'react';
import Logo from '../../assets/logo.png';

import './header.scss';

export default class Header extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="header">
               <a className="header__logo" href="https://admin.sborka.kiev.ua" aria-label="Logo">
                    <img src="https://sborka.kiev.ua/static/media/logo.b137cb3e.png" />
                </a>
                <nav>
                    <ul className="nav">
                        <li>
                            <a href="https://sborka.kiev.ua/news">Новости</a>
                        </li>
                        <li>
                            <a href="https://sborka.kiev.ua/education">Обучение</a>
                        </li>
                        <li>
                            <a href="https://sborka.kiev.ua/contacts">Контакты</a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}