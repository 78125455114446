import React from 'react';
import axios from "axios";
import config from '../../config';
import headers from "../../auth/headers";
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import formatDate from "../../date";
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import PerfectScrollbar from "react-perfect-scrollbar";

export default class News extends React.Component{
    constructor(props){
        super(props);
        this.state = { data: [], news: [], searchText: ''};
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
    }
    onSearchTextChange(e){
        this.setState({ searchText: e.target.value });
    }
    onSearchClick(){
        if(this.state.searchText.length){
            let result = [];
            this.state.data.forEach((n)=>{
                if(n.title.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > (-1)){
                    result.push(n);
                }
            });
            this.setState({ news: result });
        }else{
            this.setState({ news: this.state.data });
        }
    }
    onEditClick(e){
        window.location.href = '/news/edit/' + e.target.value;
    }
    onDeleteClick(e){
        let _this = this;
        axios.post(`${config.api}site/delete-news`,{ _id: e.target.value }, headers)
        .then(res => {
            _this.fetch();
        });
    }
    fetch(){
        let _this = this;
        axios.get(`${config.api}site/get-news`, headers)
        .then(res => {
            _this.setState({ news: res.data, data: res.data });
        });
    }
    componentDidMount(){
        this.fetch();
    }
    render(){

        const columns = [
            {
              name: 'Название',
              selector: 'title',
              sortable: true,
            },
            {
                name: 'Дата',
                width: '150px',
                selector: 'createdAt',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            formatDate(row.createdAt)
                        }</div>
                    )
                },
              },
            {
                name: 'Редактировать',
                width: '200px',
                selector: 'edit',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                           <div className="button_clickable_edit">
                              <button className="grid_edit" onClick={this.onEditClick} value={row._id}><PenIcon></PenIcon> Редактировать</button>
                           </div>
                        </div>

                    )
                },
            },
            {
                name: 'Удалить',
                width: '100px',
                selector: 'delete',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                            <div className="button_clickable">
                                <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}> </button>
                                <DeleteIcon></DeleteIcon>
                            </div>
                        </div>
                    )
                },
            }
          ];
          const customStyles = {
            header: {
              style: {
                minHeight: '56px',
              },
            },
            headRow: {
              style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
              },
            },
            headCells: {
              style: {
                '&:not(:last-of-type)': {
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                },
              },
            },
            cells: {
              style: {
                '&': {
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  paddingTop: '28px',
                  paddingBottom: '28px',
                }
              },
            },
            rows: {
                style: {
                    minHeight: '100px', // override the row height
                },
            },
          };

          createTheme('solarized', {
            background: {
            },
            context: {
              background: '#fff',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });

          const conditionalRowStyles = [
            {
              when: row => row.aprooved === true,
              style: {
                backgroundColor: '#A2E4D9'
              },
            },
            {
                when: row => row.aprooved !== true,
                style: {
                  backgroundColor: '#D5F8FE'
                },
            }
          ]
        return(
            <div className="content_body">
                 <div className="content_top">
                    <div className="row">
                        <div className="col-6">
                            <h1>Новости</h1>
                        </div>
                        <div className="col-6 search_box">
                            <div className="search_text">
                                    <input type="text" onChange={this.onSearchTextChange} value={this.state.searchText} placeholder="Поиск"></input>
                                    <button className="search_btn" onClick={this.onSearchClick}>
                                        <SearchIcon></SearchIcon>
                                    </button>
                                </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                        </div>
 
                        <div className="col-2">
                            <div className="add_article">
                                <a className="btn btn_black" href="/news/add">Добавить</a>
                            </div>
                        </div>
                    </div>
                 </div>
                <PerfectScrollbar>
                     <DataTable
                        sortable={true}
                        noHeader={true}
                        columns={columns}
                        theme="solarized"
                        data={this.state.news}
                        customStyles={customStyles}
                        pagination={true}
                        conditionalRowStyles={conditionalRowStyles}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'500px'}
                        responsive
                    />
                </PerfectScrollbar>

            </div>
        )
    }
}