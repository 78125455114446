import React,{ useState, useEffect} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import config from '../../config';
import headers from "../../auth/headers";

const Edit = ({ _id }) => {

    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}orders/get?id=${_id}`, headers)
        .then(res => {
            setData(res.data);
        });
    }

    useEffect(()=>{
        fetch();
    }, [])



    return(
             <div className="popup">
                <h2 className="popup__title">Редактировать заказ</h2>
                <ToastContainer></ToastContainer>
                {
                    data &&
                    <div className="popup__inner">
                       <Formik
                        initialValues={{ 
                            firstname: data.firstname,
                            lastname: data.lastname,
                            phone: data.phone,
                            email: data.email,
                            address: data.address,
                            comment: data.comment,
                            quantity: data.quantity,
                            link: data.link
                            
                        }}
                        onSubmit={values => {
                            values._id = _id;
                            axios.post(`${config.api}orders/update`, values, headers) 
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }).catch((err => {
                                toast.error("Ошибка сохранения", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            firstname: Yup.string().required('Обязательно'),
                            phone: Yup.string().required('Обязательно'),
                            email: Yup.string().email().required('Обязательно')
                        })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="b-form">
                                          <div className="row">
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Имя * </label>
                                                    <input
                                                        type="text"
                                                        value={values.firstname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="firstname"
                                                        placeholder=""
                                                        className={
                                                            errors.firstname && touched.firstname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.firstname && touched.firstname && (
                                                        <div className="form_error">{errors.firstname}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Номер телефона * </label>
                                                    <input
                                                        type="text"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="phone"
                                                        placeholder=""
                                                        className={
                                                            errors.phone && touched.phone
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="form_error">{errors.phone}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Адрес доставки</label>
                                                    <input
                                                        type="text"
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="address"
                                                        placeholder=""
                                                        className={
                                                            errors.address && touched.address
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.address && touched.address && (
                                                        <div className="form_error">{errors.address}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Фамилия  </label>
                                                    <input
                                                        type="text"
                                                        value={values.lastname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="lastname"
                                                        placeholder=""
                                                        className={
                                                            errors.lastname && touched.lastname
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.lastname && touched.lastname && (
                                                        <div className="form_error">{errors.lastname}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>E-mail * </label>
                                                    <input
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="email"
                                                        placeholder=""
                                                        className={
                                                            errors.email && touched.email
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="form_error">{errors.email}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Количество</label>
                                                    <input
                                                        type="number"
                                                        value={values.quantity}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="quantity"
                                                        placeholder=""
                                                        className={
                                                            errors.quantity && touched.quantity
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.quantity && touched.quantity && (
                                                        <div className="form_error">{errors.quantity}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form_group">
                                                    <label>Комментарий</label>
                                                    <textarea
                                                        value={values.comment}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="comment"
                                                        placeholder=""
                                                        className={
                                                            errors.comment && touched.comment
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                        }
                                                    ></textarea>
                                                    {errors.comment && touched.comment && (
                                                        <div className="form_error">{errors.comment}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order_popup_bottom">
                                            <div className="row">
                                            <div className="col-4">
                                            </div>
                                            <div className="col-4">
                                                <button className="order_button">Сохранить</button>
                                            </div>
                                            </div>
                                        </div>
                                       
                                    </form>
                                );  
                            }}
                        </Formik>
                  
                    </div>
                }
            </div>
    )
}

export default Edit;