import React from 'react';
import axios from "axios";
import config from '../../config';
import headers from "../../auth/headers";
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';

export default class Ideas extends React.Component{
    constructor(props){
        super(props);
        this.state = { news: []};
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.fetch = this.fetch.bind(this);
    }
    onEditClick(e){
        window.location.href = '/ideas/edit/' + e.target.value;
    }
    onDeleteClick(e){
        let _this = this;
        axios.post(`${config.api}site/delete-idea`,{ _id: e.target.value }, headers)
        .then(res => {
            _this.fetch();
        });
    }
    fetch(){
        let _this = this;
        axios.get(`${config.api}site/get-ideas`, headers)
        .then(res => {
            _this.setState({ news: res.data });
        });
    }
    componentDidMount(){
        this.fetch();
    }
    render(){
        let news = this.state.news.map((n)=>{
            return(
                <li>
                    <div className="row news-item d-flex align-items-center">
                        <div className="col-5">
                            <p>{n.title}</p>
                        </div>
                        <div className="col-4">
                           <p> {n.createdAt}</p>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <div className="edit-btns-box">
                                <div className="button_clickable">
                                    <button className="pages_edit-btn" onClick={this.onEditClick} value={n._id}>
                                            <PenIcon></PenIcon>
                                    </button>
                                </div>
                                <div className="button_clickable">
                                    <button className="pages_edit-btn" onClick={this.onDeleteClick} value={n._id}>
                                            <DeleteIcon></DeleteIcon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-10">
                            <h1>Идеи</h1>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            <div className="add_article pt-0">
                                <a className="btn btn_black" href="/ideas/add">Добавить</a>
                            </div>
                        </div>
                    </div>
                    <ul className="pages_list">
                        {
                            news
                        }
                    </ul>
                </div>
            </div>
        )
    }
}