import React,{ useState, useEffect} from 'react';
import { Formik } from "formik";
import axios from "axios";
import config from '../../../config';
import headers from "../../../auth/headers";
import * as Yup from "yup";

const EditLegal = ({ data, onCloseEdit }) => {
    return (
        <div className="row">
            <div className="col-8 add_block">
                <Formik
                            initialValues={{ name: data.name , address: data.address , inn: data.inn }}
                            onSubmit={values => {
                                values._id = data._id;
                                axios.post(`${config.api}managers/update-legal-entity`, values, headers)
                                .then(res => {
                                    onCloseEdit();
                                }).catch((err => {
                                    onCloseEdit();
                                }));
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('required'),
                                address: Yup.string().required('required'),
                                inn: Yup.number().required('required')
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="b-form">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form_group">
                                                    <label>Юр. наименование компании *</label>
                                                    <input
                                                        type="text"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="name"
                                                        placeholder="ФОП Петренко"
                                                        className={
                                                            errors.name && touched.name
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.name && touched.name && (
                                                        <div className="form_error">{errors.name}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <label>Адрес компании</label>
                                                    <input
                                                        type="text"
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="address"
                                                        placeholder="N.Y"
                                                        className={
                                                            errors.address && touched.address
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.address && touched.address && (
                                                        <div className="form_error">{errors.address}</div>
                                                    )}
                                                </div>
                                                
                                            </div>
                                            <div className="col-6 d-flex flex-column justify-content-between">
                                            
                                                <div className="form_group">
                                                    <label>ЄДРПОУ для Украины / ИНН</label>
                                                    <input
                                                        type="number"
                                                        value={values.inn}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="inn"
                                                        placeholder="0"
                                                        min="0"
                                                        className={
                                                            errors.inn && touched.inn
                                                                ? "text-input error b-form__input"
                                                                : "text-input b-form__input"
                                                        }
                                                    />
                                                    {errors.inn && touched.inn && (
                                                        <div className="form_error">{errors.inn}</div>
                                                    )}
                                                </div>
                                                <div className="form_group">
                                                    <button className="save_button">Сохранить</button>
                                                </div>
                                            </div>
                                        </div>

                                </form>
                                );
                            }}
                        </Formik>
                </div>
        </div>
    )
}

export default EditLegal;
