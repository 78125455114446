import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { SketchPicker  } from 'react-color';

const Color = ({ updateColor, edit }) => {

    const [color, setColor] = useState(edit.hex);
    const onColorChange = (color)=>{
        setColor(color.hex);
    }

    return(
             <div className="add_color_block">
                <div className="">
                     <Formik
                         initialValues={{ 
                           price: edit.price,
                         }}
                         onSubmit={values => {
                            updateColor({ price: values.price, hex: color, id: edit.id });
                         }}
                         validationSchema={Yup.object().shape({
                            price: Yup.number().required('required'),
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                      <div className="row">
                                        <div className="col-6">
                                           <SketchPicker color={ color } onChange={onColorChange}></SketchPicker>
                                        </div>
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Цена</label>
                                                <input
                                                    type="number"
                                                    value={values.price}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price"
                                                    placeholder=""
                                                    className={
                                                        errors.price && touched.price
                                                        ? "text-input error b-form__input"
                                                        : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price && touched.price && (
                                                    <div className="form_error">{errors.price}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <button className="btn btn_black">Сохранить</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            );  
                        }}
                    </Formik>
              
                </div>
            </div>
    )
}

export default Color;