import { ADD_TOKEN, ADD_PROFILE } from "../constants/action-types";

const initialState = {
  profile: {},
  token: ""
};

function rootReducer(state = initialState, action) {
    if (action.type === ADD_TOKEN) {
        return Object.assign({}, state, {
          token: action.payload
        });
    }
    else if (action.type === ADD_PROFILE) {
      return Object.assign({}, state, {
         profile: action.payload
      });
    }
    return state;
}

export default rootReducer;