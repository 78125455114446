import React from 'react';
import axios from "axios";
import config from '../../config';
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import { ReactComponent as PenIcon } from '../../assets/pen.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as CheckbocCheckedIcon } from '../../assets/checkbox_checked.svg';
import { ReactComponent as CheckbocUnCheckedIcon } from '../../assets/checkbox_unchecked.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popup from 'reactjs-popup';
import Edit from './edit/edit';
import headers from "../../auth/headers";
import formatDate from "../../date";
import DatePicker2 from "../datePicker/datePicker";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-day-picker/lib/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './managers.css';

export default class Managers extends React.Component{
    constructor(props){
        super(props);
        this.state = { startDate: null, endDate: null, data: [], id:null, searchText: '', tableHeight: ''};
        this.onStartChange = this.onStartChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onOpenEdit = this.onOpenEdit.bind(this);
        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
    }
    onResetClick(){
        this.setState({ startDate: null,  endDate: null, searchText: ''});
        let _this = this;
        setTimeout(()=>{
            _this.fetch();
        },1500)
    }
    onSearchTextChange(e){
        this.setState({ searchText: e.target.value });
    }
    onOpenEdit(e){
        this.setState({ id: e.target.value });
    }
    onCloseEdit(e){
        this.setState({ id: null });
        this.fetch();
    }
    componentDidMount(){
        this.fetch();

        //Set table height
        if(window.innerWidth < 1900) {
            this.setState({ tableHeight: 'calc(100vh - 350px)' });
        } else {
            this.setState({ tableHeight: 'calc(100vh - 430px)' });
        }
    }
    onDeleteClick(e){

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="popup-content">
                            <button className="close">
                                <CloseIcon onClick={() => {onClose();}}></CloseIcon>
                            </button>
                            <h2 className="b-popup_title">Вы уверены, что хотите удалить менеджера?</h2>
                            <p className="b-popup_text">менеджер будет удален безвозвратно</p>
                            <div className="b-popup_btns"> 
                                <button className="b-popup_btn" onClick={() =>{
                                   const _this = this;
                                   axios.post(`${config.api}managers/delete`, { _id: e.target.value }, headers).then((res) => {
                                        _this.fetch();
                                    }
                                   ).catch((err => {
                                       console.log(err);
                                   }));
                                 onClose();
                                    }}
                                >
                                    Да, удалить
                                </button>
                                <button className="b-popup_btn"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Отмена
                                </button>
                            </div>
                    </div>
                );
            }
          });  
    }
    fetch(){
        const _this = this;
        axios.get(`${config.api}managers/list?start=${this.state.startDate}&end=${this.state.endDate}&text=${this.state.searchText}`, headers).then((res) => {
            _this.setState({ data: res.data});
         }
        ).catch((err => {
            console.log(err);
        }));
    }
    onStartChange(e){
        this.setState({ startDate:e });
    }
    onEndChange(e){
        console.log(e)
        this.setState({ endDate:e });
    }
    onSearchClick(){
        this.fetch();
    }
    render(){
          const columns = [
            {
              name: 'Менеджер ID',
              selector: 'no',
                width: '140px',
              sortable: true,
              center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.no}</p>
                        }
                        </div>
                    )
                },
            },
            {
              name: 'Регистрация',
              selector: 'date',
                width: '140px',
              sortable: true,
              center: true,
              cell: row =>{
                return(
                    <div data-tag="allowRowEvents">{
                        formatDate(row.date)
                    }</div>
                )
            },
            },
            {
              name: 'Статус',
              selector: 'aprooved',
                width: '140px',
              sortable: true,
              center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.aprooved ? <span className="data_success">Подтвержденный</span> : <span className="data_error">Неподтвержденный</span>
                        }</div>
                    )
                },
            },
            {
                name: 'Юр. лицо',
                selector: 'legalentities',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    let enteties = row.legalentities ? row.legalentities.map((entety)=>{
                        return(
                            <p key={entety._id}>{entety.name}</p>
                        )
                    }) : [];
                    return(
                        <div data-tag="allowRowEvents">{

                            enteties

                        }</div>
                    )
                }
            },
            {
                name: 'Имя',
                selector: 'firstname',
                width: '140px',
                sortable: true,
                center: true,
            },
            {
                name: 'Фамилия',
                selector: 'lastname',
                width: '140px',
                sortable: true,
                center: true,
            },
            {
                name: 'Телефон регистрации',
                selector: 'phone',
                width: '140px',
                sortable: true,
                center: true,
            },
            {
              name: 'Почта регистрации',
              selector: 'email2',
                width: '200px',
              sortable: true,
              center: true,
              minWidth: '200px',
                wrap: 'true',
            },
            {
                name: 'Почта связи',
                selector: 'email',
                width: '200px',
                sortable: true,
                minWidth: '200px',
                center: true,
                wrap: 'true',
            },
            {
              name: 'Телефон связи',
              selector: 'order_phone',
                width: '140px',
              sortable: true,
              minWidth: '150px',
              center: true,
                wrap: 'true',
              cell: row =>{
                  let phones = row.phones ? row.phones.map((phone)=>{
                      return(
                          <p key={phone._id}>{phone.phone}</p>
                      )
                  }) : [];
                  return(
                      <div data-tag="allowRowEvents">{

                          phones

                      }</div>
                  )
              }
            },
            {
                name: 'Сайт',
                selector: 'site',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    let sites = row.sites ? row.sites.map((site)=>{
                        return(
                            <p key={site._id}>{site.url}</p>
                        )
                    }) : [];
                    return(
                        <div data-tag="allowRowEvents">{

                            sites

                        }</div>
                    )
                }
            },
            {
                name: 'Логотип',
                selector: 'logo',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.logo &&
                            <div className="cell_image" >
                                <img src={config.api + row.logo}></img>
                            </div>
                        }</div>
                    )
                },
            },
            {
                name: 'Адрес доставки',
                selector: 'delivery_address',
                width: '240px',
                sortable: true,
                center: true,
            },
            {
                name: 'Бонус',
                selector: 'bonus',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.bonus}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Баланс',
                selector: 'balance',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.balance}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Кредитный лимит',
                selector: 'max_credit',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.max_credit}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'iFrame',
                selector: 'allow_iframe',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.allow_iframe ? <CheckbocCheckedIcon></CheckbocCheckedIcon> : <CheckbocUnCheckedIcon></CheckbocUnCheckedIcon>
                        }</div>
                    )
                },
            },
            {
                name: 'Активные заказы',
                selector: 'orders',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.orders}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Дни хранения файлов',
                selector: 'file_save_days',
                width: '140px',
                sortable: true,
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <p className="grid_text_big">{row.file_save_days}</p>
                        }
                        </div>
                    )
                },
            },
            {
                name: 'Изменить',
                width: '200px',
                selector: 'edit',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                           <div className="button_clickable_edit">
                              <button className="grid_edit" onClick={this.onOpenEdit} value={row._id}><PenIcon></PenIcon> Редактировать</button>
                           </div>
                        </div>

                    )
                },
            },
            {
                name: 'Удалить',
                selector: 'delete',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                            <div className="button_clickable">
                                <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}> </button>
                                <DeleteIcon></DeleteIcon>
                            </div>
                        </div>
                    )
                },
            }
          ];
          const customStyles = {
            header: {
              style: {
                minHeight: '70px',
              },
            },
            headRow: {
              style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '0px',
                borderLeftColor: defaultThemes.default.divider.default,
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                  minHeight: '70px',
              },
            },
            headCells: {
              style: {
                '&:not(:last-of-type)': {
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                    paddingLeft: '20px',
                    paddingRight: '30px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    fontfamily: 'Inter',
                    fontSize: '12px',
                    lineHeight: '183%',
                    letterSpacing: '0.3px',
                },
              },
            },
            cells: {
              style: {
                '&': {
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                    fontSize: '10px',
                    lineHeight: '140%',
                }
              },
            },
            rows: {
                style: {
                    minHeight: '100px', // override the row height
                    maxHeight: '200px'
                },
            },
          };

          createTheme('solarized', {
            background: {
            },
            context: {
              background: '#cb4b16',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });

          const conditionalRowStyles = [
            {
              when: row => row.aprooved === true,
              style: {
                backgroundColor: '#A2E4D9'
              },
            },
            {
                when: row => row.aprooved !== true,
                style: {
                  backgroundColor: '#D5F8FE'
                },
            }
          ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Строк на странице',
            rangeSeparatorText: 'из',
        };

        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-6">
                            <h1>Менеджеры </h1>
                        </div>
                        <div className="col-6 search_box">
                            <div className="search_text">
                                {/*<input type="text" onChange={this.onSearchTextChange} value={this.state.searchText}></input>*/}
                                <input type="text" onChange={this.onSearchTextChange} value={this.state.searchText} placeholder="Поиск" />
                                <button className="search_btn" onClick={this.onSearchClick}>
                                    <SearchIcon></SearchIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid_top">
                    <div className="row">
                        <div className="date_input date_from">
                            <DatePicker2 onDayChange={this.onStartChange} selectedDays={this.state.startDate}></DatePicker2>
                        </div>
                        <div className="date_input date_to">
                            <DatePicker2 onDayChange={this.onEndChange} selectedDays={this.state.endDate}></DatePicker2>
                        </div>
                        <div className="">
                            <button onClick={this.onResetClick} className="reset_btn">
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                        <div className=""></div>
                        <div className="grid_top__btn-box">
                            <button onClick={this.onSearchClick} className="btn btn_black">Искать</button>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <PerfectScrollbar>
                        <DataTable
                            sortable={true}
                            noHeader={true}
                            columns={columns}
                            theme="solarized"
                            data={this.state.data}
                            customStyles={customStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={this.state.tableHeight}
                            responsive
                        />
                    </PerfectScrollbar>
                </div>
                <Popup open={this.state.id !== null} onClose={this.onCloseEdit} >
                    {
                        this.state.id &&
                        <div className="edit_content">
                            <button className="close" onClick={this.onCloseEdit}>
                                <CloseIcon></CloseIcon>
                            </button>
                            <PerfectScrollbar>
                               <Edit _id={this.state.id}></Edit>
                            </PerfectScrollbar>
                        </div>
                    }
                </Popup>
            </div>
        )
    }
}
