import React from 'react';
import axios from "axios";
import config from '../../config';
import DataTable, { defaultThemes, createTheme } from 'react-data-table-component';
import Select from 'react-select';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete_red.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import DatePicker2 from "../datePicker/datePicker";
import Auth from '../../auth/auth';
import headers from "../../auth/headers";
import Edit from './edit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popup from 'reactjs-popup';
import formatDate from "../../date";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-day-picker/lib/style.css';
import './orders.css';

const products = [
    { value: null, label: 'Выбрать товар' }
]

const statuses = [
    { value: null, label: 'Выбрать статус' },
    { value: 'sent', label: 'Заказ отправлен в работу' },
    { value: 'processing', label: 'Заказ в обработке' },
    { value: 'billed', label: 'Выставлен счет от типографии' },
    { value: 'production', label: 'Заказ в производстве' },
    { value: 'printed', label: 'Заказ напечатан' },
    { value: 'sent_to_client', label: 'Заказ отправлен клиенту' },
]

export default class managerOrders extends React.Component{
    constructor(props){
        super(props);
        this.state = { startDate: null, endDate: null, products: products, product: products[0],status: statuses[0],  data: [], id: null, searchText: '', tableHeight: ''};
        this.onStartChange = this.onStartChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.onOpenEdit = this.onOpenEdit.bind(this);
        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
    }
    onResetClick(){
        this.setState({ startDate: null,  endDate: null, searchText: '', product: products[0], status: statuses[0]});
        let _this = this;
        setTimeout(()=>{
            _this.fetch();
        },1500)
    }
    onSearchTextChange(e){
        this.setState({ searchText: e.target.value });
    }
    onDeleteClick(e){

        confirmAlert({
            customUI: ({ onClose }) => {

                return (
                    <div className="popup-content">
                            <button className="close">
                                <CloseIcon onClick={() => {onClose();}}></CloseIcon>
                            </button>
                            <h2 className="b-popup_title">Вы уверены, что хотите удалить этот заказ?</h2>
                            <p className="b-popup_text">заказ будет удален безвозвратно</p>
                            <div className="b-popup_btns"> 
                                <button className="b-popup_btn" onClick={() =>{
                                    const _this = this;
                                    axios.post(`${config.api}orders/delete`, { _id: e.target.value }, headers).then((res) => {
                                         _this.fetch();
                                     }
                                    ).catch((err => {
                                        console.log(err);
                                    }));
                                 onClose();
                                    }}
                                >
                                    Да, удалить
                                </button>
                                <button className="b-popup_btn"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Отмена
                                </button>
                            </div>
                    </div>
                );
            }
          });  
    }
    onOpenEdit(e){
        this.setState({ id: e.target.value });
    }
    onCloseEdit(e){
        this.setState({ id: null });
    }
    getProducts(){
        const _this = this;
        return new Promise((resolve, reject)=>{
            axios.get(`${config.api}products/list?start=null&end=null`).then((res) => {
                res.data.forEach(product => {
                    products.push({
                        value: product._id,
                        label: product.title_ru
                    });
                });
                _this.setState({ products });
                resolve();
             }
            ).catch((err => {
                console.log(err);       
            }));
        });
    }
    onStatusChange(e){
        this.setState({ status: e });
    }
    onProductChange(e){
        this.setState({ product: e });
    }
    componentDidMount(){
        const _this = this;
        this.getProducts().then(()=>{
            _this.fetch();
        });

        //Set table height
        if(window.innerWidth < 1900) {
            this.setState({ tableHeight: 'calc(100vh - 350px)' });
        } else {
            this.setState({ tableHeight: 'calc(100vh - 430px)' });
        }
    }
    fetch(){
        const _this = this;
        axios.get(`${config.api}orders/manager-list?start=${this.state.startDate}&end=${this.state.endDate}&product=${this.state.product.value}&status=${this.state.status.value}&id=${Auth.getManagerId()}&text=${this.state.searchText}`,headers).then((res) => {
            _this.setState({ data: res.data });
         }
        ).catch((err => {
            console.log(err);       
        }));
    }
    onStartChange(e){
        this.setState({ startDate:e });
    }
    onEndChange(e){
        this.setState({ endDate:e });
    }
    onSearchClick(){
        this.fetch();
    }
    onCopyClick(e){
        let link = e.target.value;
        var input = document.createElement('input');
        input.setAttribute('value', link);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }
    render(){
         
          let _this = this;

          const onProgressClick = (e)=>{
            axios.post(`${config.api}orders/inprogress`,{ _id: e.target.value },headers ).then((res) => {
                _this.fetch();
            }).catch((err => {
                console.log(err);       
            }));
          }

          const columns = [
            {
              name: 'ID менеджера',
              selector: 'manager_no',
              sortable: true,
                width: '140px',
            },
            {
              name: 'Юридическое лицо менеджера',
              selector: 'legal_entity',
              sortable: true,
              center: true,
                width: '140px',
            },
            {
              name: '№ заказа',
              selector: 'no',
              sortable: true,
                width: '140px',
              center: true
            },
            {
                name: 'ID клиента',
                selector: 'client_no',
                sortable: true,
                  width: '140px',
              },
            {
                name: 'Компания клиента',
                selector: 'company',
                sortable: true,
                width: '140px',
                center: true
              },
              {
                name: 'Артикул товара',
                selector: 'articule',
                sortable: true,
                  width: '140px',
                center: true
              },
            {
                name: 'Размер',
                selector: 'size',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    let sizes = row.size ? row.size.map((s)=>{
                        return <p>{s.size.label}</p>
                    }): [];
                    return(
                        <div data-tag="allowRowEvents">
                            {
                                sizes
                            }
                        </div>
                    )
                }
            },
            {
              name: 'Создание',
              selector: 'date',
              sortable: true,
                width: '140px',
              center: true,
              cell: row =>{
                return(
                    <div data-tag="allowRowEvents">{
                        formatDate(row.date)
                    }</div>
                )
            }
            },
            {
                name: 'Готовность',
                selector: 'ready_at',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                  return(
                      <div data-tag="allowRowEvents">{
                          formatDate(row.ready_at)
                      }</div>
                  )
              }
            },
            {
                name: 'Статус заказа ',
                selector: 'status',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">
                          {
                            row.status === 'new' &&
                            <button className="bt_status" value={row._id} onClick={onProgressClick}>Запустить в работу</button>
                          }
                          {
                            row.status === 'sent' &&
                            'Заказ отправлен в работу'
                          }
                          {
                            row.status === 'processing' &&
                            'Заказ в обработке'
                          }
                          {
                            row.status === 'billed' &&
                            'Выставлен счет от типографии'
                          }
                          {
                            row.status === 'production' &&
                            'Заказ в производстве'
                          }
                          {
                            row.status === 'printed' &&
                            'Заказ напечатан'
                          }
                          {
                            row.status === 'sent_to_client' &&
                            'Заказ отправлен клиенту'
                          }
                        </div>
                    )
                }
            },
            {
                name: 'Имя',
                selector: 'firstname',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Фамилия',
                selector: 'lastname',
                sortable: true,
                width: '140px',
                center: true,
            },
            ,
            {
                name: 'Номер телефона',
                selector: 'phone',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Почта',
                selector: 'email',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Кол-во',
                selector: 'quantity',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Адрес доставки',
                selector: 'address',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Комментарий',
                selector: 'comment',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Превью заказа',
                selector: 'preview',
                sortable: true,
                center: true,
                width: '200px',
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            <div className="cell_image" >
                                <img src={config.api + row.preview} alt="img"></img>
                            </div>
                        }</div>
                    )
                }
            },
            {
                name: 'Сумма заказа',
                selector: 'total',
                sortable: true,
                width: '140px',
                center: true,
            },
            {
                name: 'Сумма типографии',
                selector: 'typography_total',
                sortable: true,
                width: '140px',
                center: true
            },
            {
                name: 'Сумма бонуса',
                selector: 'bonus',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.bonus?
                            row.bonus.toFixed(2):''
                        }</div>
                    )
                }
            },
            {
                name: 'Скачать оригинал',
                selector: 'link',
                sortable: true,
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div data-tag="allowRowEvents">{
                            row.link && 
                            <button className="btn-admin" value={row.link} onClick={this.onCopyClick}>Копировать ссылку</button>
                        }</div>
                    )
                }
            },
            // {
            //     name: '',
            //     selector: 'png',
            //     sortable: true,
            //     width: '140px',
            //     center: true,
            //     cell: row =>{
            //         return(
            //             <div data-tag="allowRowEvents">{
            //                <a href={config.api + row.preview }  target="_blank">Скачать превью</a>
            //             }</div>
            //         )
            //     }
            // },
            // {
            //     name: '',
            //     selector: 'pdf',
            //     sortable: true,
            //     width: '140px',
            //     center: true,
            //     cell: row =>{
            //         return(
            //             <div data-tag="allowRowEvents">{
            //                <a href={config.api + row.pdf }  target="_blank">Скачать полный макет</a>
            //             }</div>
            //         )
            //     }
            // },
            {
                name: 'Редактировать',
                width: '200px',
                selector: 'edit',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                           <div className="button_clickable_edit">
                             <button className="grid_delete" onClick={this.onOpenEdit} value={row._id}><PenIcon></PenIcon> Редактировать</button>
                           </div>
                        </div>
                    )
                },
            },
            {
                name: 'Удалить',
                selector: 'delete',
                width: '140px',
                center: true,
                cell: row =>{
                    return(
                        <div className="grid_control_wrapper">
                            <div className="button_clickable">
                                <button className="grid_delete" onClick={this.onDeleteClick} value={row._id}> </button>
                                <DeleteIcon></DeleteIcon>
                            </div>
                        </div>
                    )
                },
            }
          ];

          const customStyles = {
            header: {
                style: {
                  minHeight: '56px',
                },
              },
              headRow: {
                style: {
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: defaultThemes.default.divider.default,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: '1px',
                  borderLeftColor: defaultThemes.default.divider.default,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: defaultThemes.default.divider.default,
                },
              },
              headCells: {
                style: {
                  '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                  },
                },
              },
              rows: {
                style: {
                    minHeight: '110px',
                }
              },
              cells: {
                style: {
                  '&': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                    paddingLeft: '8px', 
                    paddingRight: '8px',
                    paddingTop: '28px',
                    paddingBottom: '28px',
                  }
                },
              },
          };

          createTheme('solarized', {
            context: {
              background: '#cb4b16',
              text: '#FFFFFF',
            },
            divider: {
              default: '#073642',
            },
            action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
            },
          });
          const conditionalRowStyles = [
            {
              when: row => row.status === 'new',
              style: {
                backgroundColor: '#D5F8FE'
              },
            },
            {
                when: row => row.status === 'sent_to_client',
                style: {
                  backgroundColor: '#A2E4D9'
                },
            },
            {
                when: row => (row.status !== 'sent_to_client') &&  (row.status !== 'new'),
                style: {
                  backgroundColor: '#FFF'
                },
            },
          ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Строк на странице',
            rangeSeparatorText: 'из',
        };
        return(
            <div className="content_body">
                <div className="content_top">
                    <div className="row">
                        <div className="col-6">
                            <h1>Заказы клиентов</h1>
                        </div>
                        <div className="col-6 search_box">
                            <div className="search_text">
                                <input type="text" onChange={this.onSearchTextChange} value={this.state.searchText} placeholder="Поиск" />
                                <button className="search_btn" onClick={this.onSearchClick}>
                                    <SearchIcon></SearchIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid_top">
                    <div className="row">
                        <div className="col-2">
                            <DatePicker2  onDayChange={this.onStartChange} selectedDays={this.state.startDate}></DatePicker2>
                        </div>
                        <div className="col-2">
                            <DatePicker2  onDayChange={this.onEndChange} selectedDays={this.state.endDate}></DatePicker2>
                        </div>
                        <div className="col-2">
                            <Select onChange={this.onProductChange} value={this.state.product} options={this.state.products}></Select>
                        </div>
                        <div className="col-2">
                            <Select onChange={this.onStatusChange} value={this.state.status} options={statuses}></Select>
                        </div>
                        <div className="col-1">
                            <button onClick={this.onResetClick} className="reset_btn">
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                        <div className="col-3 grid_top__btn-box">
                            <button onClick={this.onSearchClick} className="btn btn_black">Искать</button>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <PerfectScrollbar>
                       <DataTable
                            sortable={true}
                            noHeader={true}
                            columns={columns}
                            theme="solarized"
                            data={this.state.data}
                            customStyles={customStyles}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={this.state.tableHeight}
                            responsive
                        />
                    </PerfectScrollbar>
                </div>
                <Popup open={this.state.id !== null} onClose={this.onCloseEdit} >
                    {
                        this.state.id && 
                        <div className="edit_content">
                            <button className="close" onClick={this.onCloseEdit}>
                                <CloseIcon></CloseIcon>
                            </button>
                            <PerfectScrollbar>
                               <Edit _id={this.state.id}></Edit>
                            </PerfectScrollbar>
                        </div>
                    }
                </Popup>
            </div>
        )
    }
}