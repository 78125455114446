import React,{ useState, useEffect} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import config from '../../config';
import headers from "../../auth/headers";
import formatDate from "../../date";
import './clients.scss';

const Orders = ({ id }) => {

    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}orders/client-orders?id=${id}`, headers)
        .then(res => {
            setData(res.data);
        });
    }

    useEffect(()=>{
        fetch();
    }, [])

    let body = data ? data.map((row)=>{
        return(
            <tr>
                <td>{ formatDate(row.date) }</td>
                <td>{ row.articule }</td>
                <td>{ row.quantity }</td>
                <td>{ row.total }</td>
                <td>{ row.typography_total }</td>
            </tr>
        )
    }): [];

    return(
        <div className="history_body">
            <h2 className="orders_history_title">История заказов</h2>
            <table className="orders_history_table">
                <tr className="orders_history_head_row">
                    <th>Создание</th>
                    <th>Артикул</th>
                    <th>Кол-во</th>
                    <th>Сумма заказа</th>
                    <th>Сумма типографии</th>
                </tr>
                {
                    body
                }
            </table>
        </div>
    )
}

export default Orders;