import React,{ useEffect } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from '../../config';
import headers from "../../auth/headers";
import { ToastContainer, toast } from 'react-toastify';
import ImageUploading from "react-images-uploading";
import './mainPage.css';

export const EditPage = ({ lang }) => {

    const [data, setData] = React.useState(null);
    const [changeImage, setChangeImage] = React.useState(false);

    const [images, setImages] = React.useState([]);
    const onChange = (imageList, addUpdateIndex) => {
        setChangeImage(true);
        setImages(imageList);
    };


    const fetch = ()=>{
        axios.get(`${config.api}site/get-main?lang=${lang}`, headers)
        .then(res => {
            setData(res.data);
        });
    }

    useEffect(()=>{
        fetch();
    }, [])

    return (
        <div>
            <div className="block">
                <div className="modal_block">
                    {
                        data && 
                        <Formik
                        initialValues={{ name: data.name, main_title: data.main_title,  main_description: data.main_description, main_button_1: data.main_button_1, main_button_2: data.main_button_2, news_title: data.news_title, gallery_title: data.gallery_title }}
                        onSubmit={values => {

                            if(images.length && changeImage){
                                let base64 = images[0]['data_url']
                                values.image = base64;
                            }else{
                                values.image = data.image;
                            }

                            values.lang = lang;

                            axios.post(`${config.api}site/update-main`, values, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }).catch((err => {
                                toast.error("Ошибка сохранения", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required('Обязательно'),
                            main_title: Yup.string().required('Обязательно'),
                            main_description: Yup.string().required('Обязательно'),
                            main_button_1: Yup.string().required('Обязательно'),
                            main_button_2: Yup.string().required('Обязательно'),
                            news_title: Yup.string().required('Обязательно'),
                            gallery_title: Yup.string().required('Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Название страницы * </label>
                                                <input
                                                    type="text"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="name"
                                                    placeholder=""
                                                    className={
                                                        errors.name && touched.name
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.name && touched.name && (
                                                    <div className="form_error">{errors.name}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Заголовок * </label>
                                                <input
                                                    type="text"
                                                    value={values.main_title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="main_title"
                                                    placeholder=""
                                                    className={
                                                        errors.main_title && touched.main_title
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.main_title && touched.main_title && (
                                                    <div className="form_error">{errors.main_title}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Кнопка * </label>
                                                <input
                                                    type="text"
                                                    value={values.main_button_1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="main_button_1"
                                                    placeholder=""
                                                    className={
                                                        errors.main_button_1 && touched.main_button_1
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.main_button_1 && touched.main_button_1 && (
                                                    <div className="form_error">{errors.main_button_1}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Текст </label>
                                                <textarea
                                                    rows="10"
                                                    value={values.main_description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="main_description"
                                                    placeholder=""
                                                    className={
                                                        errors.main_description && touched.main_description
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.main_description && touched.main_description && (
                                                    <div className="form_error">{errors.main_description}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            {/* <div className="form_group">
                                                <label>Кнопка 2* </label>
                                                <input
                                                    type="text"
                                                    value={values.main_button_2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="main_button_2"
                                                    placeholder=""
                                                    className={
                                                        errors.main_button_2 && touched.main_button_2
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.main_button_2 && touched.main_button_2 && (
                                                    <div className="form_error">{errors.main_button_2}</div>
                                                )}
                                            </div> */}

                                            <div className="form_group">
                                                <label>Блок новости Заголовок * </label>
                                                <input
                                                    type="text"
                                                    value={values.news_title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="news_title"
                                                    placeholder=""
                                                    className={
                                                        errors.news_title && touched.news_title
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.news_title && touched.news_title && (
                                                    <div className="form_error">{errors.news_title}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Блок фотогалерея Заголовок * </label>
                                                <input
                                                    type="text"
                                                    value={values.gallery_title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="gallery_title"
                                                    placeholder=""
                                                    className={
                                                        errors.gallery_title && touched.gallery_title
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.gallery_title && touched.gallery_title && (
                                                    <div className="form_error">{errors.gallery_title}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <button className="save_button">Сохранить</button>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
                     
                    }
                    {
                    (data != null) && (data.image) && (!changeImage) &&
                        <div className="row">
                            <div className="col-4">
                               <div className="form_group">
                                   <label>Фон первого экрана</label>
                                   <div className="logo_wrapper">
                                       <img src={config.api + data.image}></img>
                                   </div>
                               </div>
                               <div className="form_group">
                                  <button className="btn btn_black" onClick={()=>{ setChangeImage(true)}}>Изменить</button>
                               </div>
                            </div>
                        </div>
                    }
                    {
                     (data != null) && (!data.image || changeImage) &&
                                                <ImageUploading
                                                        multiple
                                                        value={images}
                                                        onChange={onChange}
                                                        maxNumber={1}
                                                        dataURLKey="data_url"
                                                    >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                        
                                                <div className="upload__image-wrapper">
                                                    <div className="row">
                                                            <div className="col-3">
                                                                <div className="form_group">
                                                                    {
                                                                        imageList.length < 1 &&
                                                                        <button
                                                                            className="btn btn_black"
                                                                            onClick={onImageUpload}
                                                                            {...dragProps}
                                                                        >
                                                                            Выбрать файл
                                                                        </button>
                                                                    }
                                                                    
                                                                    {
                                                                        imageList.map((image, index) => (
                                                                            <div key={index} className="image-item">
                                                                                <div className="logo_wrapper">
                                                                                <img src={image.data_url} />
                                                                                </div>
                                                                                <div className="image-item__btn-wrapper">
                                                                                    <div className="form_group">
                                                                                    <button onClick={() => onImageUpdate(index)}  className="btn btn_black">Обновить</button>
                                                                                    </div>
                                                                                    <div className="form_group">
                                                                                    <button onClick={() => onImageRemove(index)}  className="btn btn_black">Удалить</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                )}
                        </ImageUploading>
                     }
                </div>
         
            </div>
            <ToastContainer></ToastContainer>

        </div>
    )
}
