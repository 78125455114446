import React from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import createImagePlugin from '@draft-js-plugins/image';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./richEditor.css";
import "@draft-js-plugins/image/lib/plugin.css";
import config from '../../config'

const imagePlugin = createImagePlugin();

export default class RichEditor extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          editorState: EditorState.createEmpty(),
        };
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
      }
    
      onEditorStateChange(editorState){
        this.setState({
          editorState,
        });
        this.props.onChangeContent(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      };

      componentDidMount(){
          if(this.props.content){

            const blocksFromHtml = htmlToDraft(this.props.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                editorState
            });
          }
      }

      // _uploadImageCallBack(file){
      //   const imageObject = {
      //     file: file,
      //     localSrc: URL.createObjectURL(file),
      //   }
      //   return new Promise(
      //     (resolve, reject) => {
      //       resolve({ data: { link: imageObject.localSrc } });
      //     }
      //   );
      // }

      _uploadImageCallBack = (file) => {
        const formData = new FormData();
        formData.append('image', file);
      
        return fetch(`${config.api}site/upload-image`, {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(data => {
          console.log(data.imageUrl)
          return { data: { link: data.imageUrl } };
        });
      };
    
      render(){
        const { editorState } = this.state;
        return <div className='rich_editor'>
          <Editor
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}    
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: { uploadCallback: this._uploadImageCallBack }
            }}
            plugins={[imagePlugin]}
          />
        </div>
      }
  }