import React,{ useState, useEffect } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from '../../../config';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ImageUploading from "react-images-uploading";
import headers from "../../../auth/headers";
import Colors from "../colors/colors";
import { subcategories } from '../categories';
import { types } from '../types';
import Sizes from '../sizes/sizes';
import '../product.css';
import '../products.css';

const AddProducts = ({  }) => {

    const [colors, setColors] = useState([]);
    const [model, setModel] = useState(null);
    const [images, setImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sizes, setSizes] = useState([]);

    const onColorsChange = (hexes)=>{
        setColors(hexes);
    }

    const fetch = ()=>{
        axios.get(`${config.api}products/categories`, headers)
        .then(res => {
            let responseData = res.data;
            let categories = [];
            responseData.forEach((i)=>{
                categories.push({ value: i.id, label: i.name })
            });
            setCategories(categories);
        }).catch((err => {
        
        }));
    }

    useEffect(()=>{
        fetch();
    }, [])

    const onUploadModel = (e)=>{
        let file = e.target.files[0];
        e.target.value = '';
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            let result = reader.result;
            setModel(result);
        };
    }

    const onChange = (imageList, addUpdateIndex) => {
      setImages(imageList);
    };

    return (
        <div className="content_body">
            <div className="content_top">
                <div className="row">
                    <div className="col-9">
                        <h1>Добавить</h1>
                    </div>
                </div>
            </div>
            <div className="modal_block">
            <Sizes setSizes={setSizes} sizes={sizes}></Sizes>
            <Colors onColorsChange={onColorsChange}></Colors>
            <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={1}
                        dataURLKey="data_url"
                    >
                        {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps
                        }) => (
                        <div className="upload__image-wrapper">
                            {
                                imageList.length < 1 &&
                                <button
                                    style={isDragging ? { color: "red" } : null}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    Click or Drop here
                                </button>
                            }
                            {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image.data_url} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                    <button onClick={() => onImageUpdate(index)}>Update</button>
                                    <button onClick={() => onImageRemove(index)}>Remove</button>
                                </div>
                            </div>
                            ))}
                        </div>
                        )}
                    </ImageUploading>
                   
                  <Formik
                        initialValues={{ 
                            articule: "",
                            title_ru:"",
                            title_en:"",
                            title_uk:"",
                            class: "",
                            makeready_price: null,
                            description_ru: "",
                            description_en: "",
                            description_uk: "",
                            editable: true,
                            full_print: false,
                            env: false,
                            light: false,
                            category: null,
                            sub_category: { value: 'none', label: 'None' },
                            type: { value: 'none', label: 'None' },
                            price_1_5: null,
                            price_6_10: null,
                            price_11_50: null,
                            price_51_100: null,
                            price_101_150: null,
                            price_151_200: null,
                            price_201_500: null,
                            price_501_1000: null
                        }}
                        onSubmit={values => {

                            values.colors = colors;
                            values.category = values.category.value;
                            values.sub_category = values.sub_category.value;
                            values.type = values.type.value;
                            values.model = model;
                            values.size = sizes;

                            if(images.length){
                                let base64 = images[0]['data_url'];
                                values.preview = base64;
                            }

                            if(!values.preview){
                                toast.error("Картинка обязательно", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                                return;
                            }

                            axios.post(`${config.api}products/create-product`, values, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }).catch((err => {
                                toast.error("Ошибка сохранения", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }));

                        }}
                        validationSchema={Yup.object().shape({
                            articule: Yup.string().required('required'),
                            title_ru: Yup.string().required('required'),
                            title_en: Yup.string().required('required'),
                            title_uk: Yup.string().required('required'),
                            category: Yup.object().required('required'),
                            price_1_5: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_6_10: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_11_50: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_51_100: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_101_150: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_151_200: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_201_500: Yup.number().notOneOf([0], 'not zero').required('required'),
                            price_501_1000: Yup.number().notOneOf([0], 'not zero').required('required'),
                            makeready_price: Yup.number().required('required')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Артикул товара* </label>
                                                <input
                                                    type="text"
                                                    value={values.articule}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="articule"
                                                    placeholder=""
                                                    className={
                                                        errors.articule && touched.articule
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.articule && touched.articule && (
                                                    <div className="form_error">{errors.articule}</div>
                                                )}
                                            </div>
                                            
                                            <div className="form_group">
                                                <label>Категория </label>
                                                <Select options={categories} value={values.category} onChange={option => setFieldValue("category", option)}/>
                                                {errors.category && touched.category && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 1 - 5</label>
                                                <input
                                                    type="number"
                                                    value={values.price_1_5}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_1_5"
                                                    placeholder=""
                                                    className={
                                                        errors.price_1_5 && touched.price_1_5
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_1_5 && touched.price_1_5 && (
                                                    <div className="form_error">{errors.price_1_5}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 6 - 10</label>
                                                <input
                                                    type="number"
                                                    value={values.price_6_10}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_6_10"
                                                    placeholder=""
                                                    className={
                                                        errors.price_6_10 && touched.price_6_10
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_6_10 && touched.price_6_10 && (
                                                    <div className="form_error">{errors.price_6_10}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 11 - 50</label>
                                                <input
                                                    type="number"
                                                    value={values.price_11_50}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_11_50"
                                                    placeholder=""
                                                    className={
                                                        errors.price_11_50 && touched.price_11_50
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_11_50 && touched.price_11_50 && (
                                                    <div className="form_error">{errors.price_11_50}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 51 - 100</label>
                                                <input
                                                    type="number"
                                                    value={values.price_51_100}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_51_100"
                                                    placeholder=""
                                                    className={
                                                        errors.price_51_100 && touched.price_51_100
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_51_100 && touched.price_51_100 && (
                                                    <div className="form_error">{errors.price_51_100}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 101 - 150</label>
                                                <input
                                                    type="number"
                                                    value={values.price_101_150}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_101_150"
                                                    placeholder=""
                                                    className={
                                                        errors.price_101_150 && touched.price_101_150
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_101_150 && touched.price_101_150 && (
                                                    <div className="form_error">{errors.price_101_150}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 151 - 200</label>
                                                <input
                                                    type="number"
                                                    value={values.price_151_200}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_151_200"
                                                    placeholder=""
                                                    className={
                                                        errors.price_151_200 && touched.price_151_200
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_151_200 && touched.price_151_200 && (
                                                    <div className="form_error">{errors.price_151_200}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 201 - 500</label>
                                                <input
                                                    type="number"
                                                    value={values.price_201_500}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_201_500"
                                                    placeholder=""
                                                    className={
                                                        errors.price_201_500 && touched.price_201_500
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_201_500 && touched.price_201_500 && (
                                                    <div className="form_error">{errors.price_201_500}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 501 - 1000</label>
                                                <input
                                                    type="number"
                                                    value={values.price_501_1000}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_501_1000"
                                                    placeholder=""
                                                    className={
                                                        errors.price_501_1000 && touched.price_501_1000
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_501_1000 && touched.price_501_1000 && (
                                                    <div className="form_error">{errors.price_501_1000}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Отображать в Конструкторе</label>
                                                <Field type="checkbox" name="editable" />
                                            </div>
                                            <div className="form_group">
                                                <label>Полная запечатка</label>
                                                <Field type="checkbox" name="full_print" />
                                            </div>
                                            <div className="form_group">
                                                <label>Отражение</label>
                                                <Field type="checkbox" name="env" />
                                            </div>
                                            <div className="form_group">
                                                <label>Cвет</label>
                                                <Field type="checkbox" name="light" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Название товара RU*</label>
                                                <input
                                                    type="text"
                                                    value={values.title_ru}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title_ru"
                                                    placeholder=""
                                                    className={
                                                        errors.title_ru && touched.title_ru
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title_ru && touched.title_ru && (
                                                    <div className="form_error">{errors.title_ru}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Название товара EN*</label>
                                                <input
                                                    type="text"
                                                    value={values.title_en}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title_en"
                                                    placeholder=""
                                                    className={
                                                        errors.title_en && touched.title_en
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title_en && touched.title_en && (
                                                    <div className="form_error">{errors.title_en}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Название товара UK*</label>
                                                <input
                                                    type="text"
                                                    value={values.title_uk}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title_uk"
                                                    placeholder=""
                                                    className={
                                                        errors.title_uk && touched.title_uk
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title_uk && touched.title_uk && (
                                                    <div className="form_error">{errors.title_uk}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Подкатегория товара</label>
                                                <Select options={subcategories} value={values.sub_category} onChange={option => setFieldValue("sub_category", option)}/>
                                                {errors.sub_category && touched.sub_category && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Тип</label>
                                                <Select options={types} value={values.type} onChange={option => setFieldValue("type", option)}/>
                                                {errors.type && touched.type && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Стоимость приладки ₴</label>
                                                <input
                                                    type="number"
                                                    value={values.makeready_price}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="makeready_price"
                                                    placeholder=""
                                                    className={
                                                        errors.makeready_price && touched.makeready_price
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.makeready_price && touched.makeready_price && (
                                                    <div className="form_error">{errors.makeready_price}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Модель</label>
                                                <input 
                                                    type="file" 
                                                    accept=".glb"
                                                    onChange={onUploadModel}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_group">
                                                <label>Описание товара Ru</label>
                                                <textarea
                                                    rows={5}
                                                    value={values.description_ru}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description_ru"
                                                    placeholder=""
                                                    className={
                                                        errors.description_ru && touched.description_ru
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.description_ru && touched.description_ru && (
                                                    <div className="form_error">{errors.description_ru}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Описание товара En</label>
                                                <textarea
                                                    rows={5}
                                                    value={values.description_en}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description_en"
                                                    placeholder=""
                                                    className={
                                                        errors.description_en && touched.description_en
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.description_en && touched.description_en && (
                                                    <div className="form_error">{errors.description_en}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Описание товара Uk</label>
                                                <textarea
                                                    rows={5}
                                                    value={values.description_uk}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description_uk"
                                                    placeholder=""
                                                    className={
                                                        errors.description_uk && touched.description_uk
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.description_uk && touched.description_uk && (
                                                    <div className="form_error">{errors.description_uk}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_group">
                                                <button className="save_button">Сохранить</button>
                                            </div>
                                        </div>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
                <ToastContainer></ToastContainer>
            </div>
        </div>
      
    )
}

export default AddProducts;
