import React from 'react';
import { Gallery } from './gallery';
import { EditPage } from './editPage';
import './mainPage.css';

const MainPage = ({}) => {
    return (
        <div className="content_body">
            <div className="content_top">
                <div className="row">
                    <div className="col-9">
                        <h1>Главная страница </h1>
                     </div>
                 </div>
            </div>
            <div className="admin-wrapper">
                <h3>Українська версія</h3>
                <EditPage lang="uk"></EditPage>
                <h3>Русская версия</h3>
                <EditPage lang="ru"></EditPage>
                <h3>English version</h3>
                <EditPage lang="en"></EditPage>
                <Gallery></Gallery>
            </div>
        </div>
    )
}

export default MainPage;
