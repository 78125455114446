import React from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import Select from 'react-select';
import axios from "axios";
import config from '../../config';
import headers from "../../auth/headers";
import { ToastContainer, toast } from 'react-toastify';
import { langs } from '../../langs';
import { Gallery } from './gallery';
import './ideas.css';
import {ReactComponent as ArrowIcon} from "../../assets/back.svg";
import {useHistory} from "react-router-dom";

const Add = () => {

    const [gallery, setGalley] = React.useState([]);
    const setIdeaImage = (imageList) => {
        setGalley(imageList);
    };

    const history = useHistory();

    return (
        <div className="content_body">
            <button className="back_button" onClick={() => history.goBack()}>
                <ArrowIcon></ArrowIcon>
            </button>
            <h1>Добавить идею</h1>
            <div className="admin-wrapper">
                  <Formik
                        initialValues={{ title: "", tab: "",  description: "", lang: langs[0], image: ""}}
                        onSubmit={values => {
                            values.lang = values.lang.value;
                            values.gallery = gallery;
                            axios.post(`${config.api}site/create-idea`, values, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            });
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required('Обязательно'),
                            tab: Yup.string().required('Обязательно'),
                            description: Yup.string().required('Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-6">
                                            <div className="form_group">
                                                        <label>Картина * </label>
                                                        <textarea
                                                            rows="10"
                                                            type="text"
                                                            value={values.image}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="image"
                                                            placeholder=""
                                                            className={
                                                                errors.image && touched.image
                                                                    ? "text-input error b-form__input"
                                                                    : "text-input b-form__input"
                                                            }
                                                        />
                                                        {errors.image && touched.image && (
                                                            <div className="form_error">{errors.image}</div>
                                                        )}
                                            </div>
                                            <div className="form_group">
                                                <label>Заголовок * </label>
                                                <input
                                                    type="text"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title"
                                                    placeholder=""
                                                    className={
                                                        errors.title && touched.title
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title && touched.title && (
                                                    <div className="form_error">{errors.title}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Таб * </label>
                                                <input
                                                    type="text"
                                                    value={values.tab}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="tab"
                                                    placeholder=""
                                                    className={
                                                        errors.tab && touched.tab
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.tab && touched.tab && (
                                                    <div className="form_error">{errors.tab}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Контент * </label>
                                                <textarea
                                                    rows="10"
                                                    type="text"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description"
                                                    placeholder=""
                                                    className={
                                                        errors.description && touched.description
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.description && touched.description && (
                                                    <div className="form_error">{errors.description}</div>
                                                )}
                                            </div>

                                            <div className="form_group">
                                                <label>Язык </label>
                                                <Select options={langs} value={values.lang} onChange={option => setFieldValue("lang", option)}/>
                                                {errors.lang && touched.lang && (
                                                    <div className="form_error">lang</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <button className="save_button">Сохранить</button>
                                            </div>
                                        </div>

                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
                    <ToastContainer></ToastContainer>
            </div>
            <Gallery setIdeaImage={setIdeaImage}></Gallery>
        </div>
      
    )
}

export default Add;
