import React,{ useState, useEffect } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from '../../../config';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ImageUploading from "react-images-uploading";
import headers from "../../../auth/headers";
import Colors from "../colors/colors";
import { subcategories } from '../categories';
import { types } from '../types';
import Sizes from '../sizes/sizes';
import '../product.css';
import '../products.css';


const EditProducts = ({ _id }) => {

    const [data, setData] = React.useState(null);
    const [model, setModel] = useState(null);
    const [colors, setColors] = useState([]);
    const [changeLogo, setChangeLogo] = React.useState(false);
    const [categories, setCategories] = useState([]);
    const [sizes, setSizes] = useState([]);

    const onColorsChange = (hexes)=>{
        setColors(hexes);
    }

    const getCategories = ()=>{
        return new Promise((resolve, reject)=>{
            axios.get(`${config.api}products/categories`, headers)
            .then(res => {
                let responseData = res.data;
                let categories = [];
                responseData.forEach((i)=>{
                    categories.push({ value: i.id, label: i.name })
                });
                setCategories(categories);
                resolve(categories);
            }).catch((err => {
            
            }));
        });
    }


    const [images, setImages] = React.useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        setChangeLogo(true);
        setImages(imageList);
    };

    const onUploadModel = (e)=>{
        let file = e.target.files[0];
        e.target.value = '';
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            let result = reader.result;
            setModel(result);
        };
    }

    const fetch = (categories)=>{
        axios.get(`${config.api}products/get?id=${_id}`, headers)
        .then(res => {
            let responseData = res.data;
            setColors(responseData.colors);
            setModel(responseData.model);
            categories.forEach((cat)=>{
                if(cat.value === responseData.category){
                    responseData.category = cat;
                }
            });
            subcategories.forEach((cat)=>{
                if(cat.value === responseData.sub_category){
                    responseData.sub_category = cat;
                }
            });
            types.forEach((cat)=>{
                if(cat.value === responseData.type){
                    responseData.type = cat;
                }
            });
            setSizes(responseData.size);
            setData(responseData);
        }).catch((err => {
        
        }));
    }

    useEffect(()=>{
        getCategories().then((categories)=>{
            fetch(categories);
        });
    }, [])


    return (
        <div className="popup">
            <h2 className="popup__title">Редактировать товар</h2>
            <div className="popup__inner">
                <div className="block">
                    {
                        data &&
                        <Sizes setSizes={setSizes} sizes={sizes}></Sizes>
                    }
                </div>
                {
                    (data != null) && (data.preview) && (!changeLogo) &&
                    <div className="row block">
                            <div className="col-2">
                               <div className="form_group">
                                   <label>Лого</label>
                                   <div className="logo_wrapper">
                                       <img src={config.api + data.preview}></img>
                                   </div>
                               </div>
                               <div className="form_group">
                                  <button className="btn btn_black" onClick={()=>{ setChangeLogo(true)}}>Изменить</button>
                               </div>
                            </div>
                    </div>
                }
                {
                         (data != null) && (!data.preview || changeLogo) &&
                         <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={1}
                                dataURLKey="data_url"
                            >
                         {({
                             imageList,
                             onImageUpload,
                             onImageUpdate,
                             onImageRemove,
                             dragProps
                         }) => (
 
                         <div className="upload__image-wrapper">
                               <div className="row">
                                    <div className="col-3">
                                        <div className="form_group">
                                            {
                                                imageList.length < 1 &&
                                                <button
                                                    className="btn btn_black"
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Выбрать файл
                                                </button>
                                            }
                                            
                                            {
                                                imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <div className="logo_wrapper">
                                                        <img src={image.data_url} />
                                                        </div>
                                                        <div className="image-item__btn-wrapper">
                                                            <div className="form_group">
                                                            <button onClick={() => onImageUpdate(index)}  className="btn btn_black">Update</button>
                                                            </div>
                                                            <div className="form_group">
                                                            <button onClick={() => onImageRemove(index)}  className="btn btn_black">Remove</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                         </div>
                         )}
                     </ImageUploading>
                }
                <div className="block">
                    <Colors onColorsChange={onColorsChange} colors={colors}></Colors>
                </div>
                {
                    data &&
                    <Formik
                        initialValues={{ 
                            articule: data.articule,
                            title_ru: data.title_ru,
                            title_en: data.title_en,
                            title_uk: data.title_uk,
                            makeready_price:  data.makeready_price,
                            description_ru: data.description_ru,
                            description_en: data.description_en,
                            description_uk: data.description_uk,
                            category: data.category,
                            sub_category: data.sub_category,
                            type: data.type,
                            price_1_5: data.price_1_5,
                            price_6_10: data.price_6_10,
                            price_11_50: data.price_11_50,
                            price_51_100: data.price_51_100,
                            price_101_150: data.price_101_150,
                            price_151_200: data.price_151_200,
                            price_201_500: data.price_201_500,
                            price_501_1000: data.price_501_1000,
                            full_print: data.full_print,
                            editable: data.editable,
                            env: data.env,
                            light: data.light
                        }}
                        onSubmit={values => {

                            let request_data = Object.assign({}, values);

                            request_data._id = data._id;
                            request_data.colors = colors;
                            request_data.category = values.category.value;
                            request_data.sub_category = values.sub_category.value;
                            request_data.type = values.type.value;
                            request_data.model = model;
                            request_data.size = sizes;

                            if(images.length && changeLogo){
                                let base64 = images[0]['data_url']
                                request_data.preview = base64;
                            }else{
                                request_data.preview = data.preview;
                            }

                            if(!request_data.preview){
                                toast.error("Картинка обязательно", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                                return;
                            }

                            axios.post(`${config.api}products/update-product`, request_data, headers)
                            .then(res => {
                                toast.success("Сохранено", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }).catch((err => {
                                toast.error("Ошибка сохранения", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            articule: Yup.string().required('Обязательно'),
                            title_ru: Yup.string().required('Обязательно'),
                            title_en: Yup.string().required('Обязательно'),
                            title_uk: Yup.string().required('Обязательно'),
                            category: Yup.object().required('Обязательно'),
                            price_1_5: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_6_10: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_11_50: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_51_100: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_101_150: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_151_200: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_201_500: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            price_501_1000: Yup.number().notOneOf([0], 'not zero').required('Обязательно'),
                            makeready_price: Yup.number().required('Обязательно')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                     <div className="row">
                                        <div className="col-6">
                                            <div className="form_group">
                                                <label>Артикул товара * </label>
                                                <input
                                                    type="text"
                                                    value={values.articule}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="articule"
                                                    placeholder=""
                                                    className={
                                                        errors.articule && touched.articule
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.articule && touched.articule && (
                                                    <div className="form_error">{errors.articule}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Категория </label>
                                                <Select options={categories} value={values.category} onChange={option => setFieldValue("category", option)}/>
                                                {errors.category && touched.category && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Размер </label>
                                                <input
                                                    type="number"
                                                    value={values.size}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="size"
                                                    placeholder=""
                                                    className={
                                                        errors.size && touched.size
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.size && touched.size && (
                                                    <div className="form_error">{errors.size}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 1 - 5</label>
                                                <input
                                                    type="number"
                                                    value={values.price_1_5}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_1_5"
                                                    placeholder=""
                                                    className={
                                                        errors.price_1_5 && touched.price_1_5
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_1_5 && touched.price_1_5 && (
                                                    <div className="form_error">{errors.price_1_5}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 6 - 10</label>
                                                <input
                                                    type="number"
                                                    value={values.price_6_10}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_6_10"
                                                    placeholder=""
                                                    className={
                                                        errors.price_6_10 && touched.price_6_10
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_6_10 && touched.price_6_10 && (
                                                    <div className="form_error">{errors.price_6_10}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 11 - 50</label>
                                                <input
                                                    type="number"
                                                    value={values.price_11_50}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_11_50"
                                                    placeholder=""
                                                    className={
                                                        errors.price_11_50 && touched.price_11_50
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_11_50 && touched.price_11_50 && (
                                                    <div className="form_error">{errors.price_11_50}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 51 - 100</label>
                                                <input
                                                    type="number"
                                                    value={values.price_51_100}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_51_100"
                                                    placeholder=""
                                                    className={
                                                        errors.price_51_100 && touched.price_51_100
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_51_100 && touched.price_51_100 && (
                                                    <div className="form_error">{errors.price_51_100}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 101 - 150</label>
                                                <input
                                                    type="number"
                                                    value={values.price_101_150}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_101_150"
                                                    placeholder=""
                                                    className={
                                                        errors.price_101_150 && touched.price_101_150
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_101_150 && touched.price_101_150 && (
                                                    <div className="form_error">{errors.price_101_150}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 151 - 200</label>
                                                <input
                                                    type="number"
                                                    value={values.price_151_200}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_151_200"
                                                    placeholder=""
                                                    className={
                                                        errors.price_151_200 && touched.price_151_200
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_151_200 && touched.price_151_200 && (
                                                    <div className="form_error">{errors.price_151_200}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 201 - 500</label>
                                                <input
                                                    type="number"
                                                    value={values.price_201_500}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_201_500"
                                                    placeholder=""
                                                    className={
                                                        errors.price_201_500 && touched.price_201_500
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_201_500 && touched.price_201_500 && (
                                                    <div className="form_error">{errors.price_201_500}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Стоимость 501 - 1000</label>
                                                <input
                                                    type="number"
                                                    value={values.price_501_1000}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="price_501_1000"
                                                    placeholder=""
                                                    className={
                                                        errors.price_501_1000 && touched.price_501_1000
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.price_501_1000 && touched.price_501_1000 && (
                                                    <div className="form_error">{errors.price_501_1000}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                        <div className="form_group">
                                                <label>Название товара RU *</label>
                                                <input
                                                    type="text"
                                                    value={values.title_ru}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title_ru"
                                                    placeholder=""
                                                    className={
                                                        errors.title_ru && touched.title_ru
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title_ru && touched.title_ru && (
                                                    <div className="form_error">{errors.title_ru}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Название товара EN *</label>
                                                <input
                                                    type="text"
                                                    value={values.title_en}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title_en"
                                                    placeholder=""
                                                    className={
                                                        errors.title_en && touched.title_en
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title_en && touched.title_en && (
                                                    <div className="form_error">{errors.title_en}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Название товара UK *</label>
                                                <input
                                                    type="text"
                                                    value={values.title_uk}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="title_uk"
                                                    placeholder=""
                                                    className={
                                                        errors.title_uk && touched.title_uk
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.title_uk && touched.title_uk && (
                                                    <div className="form_error">{errors.title_uk}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Подкатегория товара</label>
                                                <Select options={subcategories} value={values.sub_category} onChange={option => setFieldValue("sub_category", option)}/>
                                                {errors.sub_category && touched.sub_category && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Тип</label>
                                                <Select options={types} value={values.type} onChange={option => setFieldValue("type", option)}/>
                                                {errors.type && touched.type && (
                                                    <div className="form_error">required</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Модель</label>
                                                <input 
                                                    type="file" 
                                                    accept=".glb"
                                                    onChange={onUploadModel}
                                                />
                                            </div>

                                            <div className="form_group">
                                                <label>Стоимость приладки ₴</label>
                                                <input
                                                    type="number"
                                                    value={values.makeready_price}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="makeready_price"
                                                    placeholder=""
                                                    className={
                                                        errors.makeready_price && touched.makeready_price
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.makeready_price && touched.makeready_price && (
                                                    <div className="form_error">{errors.makeready_price}</div>
                                                )}
                                            </div>
                                            <div className="form_group d-flex align-items-center">
                                                <Field type="checkbox" name="editable" />
                                                <label className="pl-2 pb-0">Отображать в Конструкторе</label>
                                            </div>
                                            <div className="form_group d-flex align-items-center">
                                                <Field type="checkbox" name="env" />
                                                <label className="pl-2 pb-0">Отражение</label>
                                            </div>
                                            <div className="form_group d-flex align-items-center">
                                                <Field type="checkbox" name="light" />
                                                <label className="pl-2 pb-0">Cвет</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form_group">
                                                <label>Описание товара RU</label>
                                                <textarea
                                                    rows={5}
                                                    value={values.description_ru}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description_ru"
                                                    placeholder=""
                                                    className={
                                                        errors.description_ru && touched.description_ru
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.description_ru && touched.description_ru && (
                                                    <div className="form_error">{errors.description_ru}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Описание товара EN</label>
                                                <textarea
                                                    rows={5}
                                                    value={values.description_en}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description_en"
                                                    placeholder=""
                                                    className={
                                                        errors.description_en && touched.description_en
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.description_en && touched.description_en && (
                                                    <div className="form_error">{errors.description_en}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>Описание товара UK</label>
                                                <textarea
                                                    rows={5}
                                                    value={values.description_uk}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="description_uk"
                                                    placeholder=""
                                                    className={
                                                        errors.description_uk && touched.description_uk
                                                            ? "text-input error b-form__input"
                                                            : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.description_uk && touched.description_uk && (
                                                    <div className="form_error">{errors.description_uk}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_group">
                                                <button className="save_button">Сохранить</button>
                                            </div>
                                        </div>
                                    </div>

                            </form>
                            );
                        }}
                    </Formik>
                }
                 <ToastContainer></ToastContainer>
            </div>
        </div>
      
    )
}

export default EditProducts;
