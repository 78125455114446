let profileSaved = null;

const Auth = {
    isAuthenticated: () => {
        const token = localStorage.getItem('TOKEN');
        if (token) {
            return true;
        }
        return false;
    },
    isAdmin: ()=>{
        if(!profileSaved){
            let profile = localStorage.getItem("profile");
            if(profile){
                profileSaved = JSON.parse(profile);
            }else{
                return false;
            }
        }
        if(profileSaved.role === 'admin'){
            return true;
        }else{
            return false;
        }
    },
    getManagerId: ()=>{
        let profile = localStorage.getItem("profile");
        if(profile){
            profile = JSON.parse(profile);
            return profile.manager_id;
        }else{
            return null;
        }
    }
}
export default Auth;