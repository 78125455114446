import React, { useEffect} from 'react';
import config from '../../config';
import ImageUploading from 'react-images-uploading';
import './gallery.css';
import { ToastContainer, toast } from 'react-toastify';

export function Gallery ({ setIdeaImage, imageList }) {

  const [images, setImages] = React.useState([]);
  const [initImage, setInitImage] = React.useState(false);
  const maxNumber = 69;

  const onChange = (imageList2, addUpdateIndex) => {

    setImages(imageList2);

    let urls = [];

    imageList2.forEach((i)=>{
        if(typeof i === 'string'){
          urls.push(i);
        }else{
          urls.push(i['data_url']);
        }
    });

    setIdeaImage(urls);
  };

  useEffect(()=>{
    if(imageList && !initImage){
      setImages(imageList);
      setInitImage(true);
    }
  },[])


  return (
    <div className="block">
      <h2>Блок фотогалерея</h2>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className="gallery_controls">
              <button
                className="btn btn_black"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                Загрузить
              </button>
              &nbsp;
              <button className="btn btn_black" onClick={onImageRemoveAll}>Удалить все</button>
            </div>
            {imageList.map((image, index) => { 
              return(
              <div key={index} className="gallery_item">
                <div className="row">
                  <div className="col-3">
                     <div className="gallery_image">
                       {
                          typeof image === 'string' &&
                          <img src={config.api + image} alt="1" width="100" />
                       }
                       {
                          typeof image === 'object' &&
                          <img src={image['data_url']} alt="" width="100" />
                       }
                     </div>
                  </div> 
                  <div className="col-3">
                     <div className="gallery_item_controls">
                      <button className="btn btn_black" onClick={() => onImageUpdate(index)}>Изменить</button>
                      <button className="btn btn_black" onClick={() => onImageRemove(index)}>Удалить</button>
                     </div>
                  </div>
                </div>
              </div>
            )})}
           
          </div>
        )}
      </ImageUploading>
      <ToastContainer></ToastContainer>
    </div>
  );
}